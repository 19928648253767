@import "mixins/sizing";
@import "Parent/scss/settings/partials/sizing-utilities";
@import "mixins/breakpoints";
@import "Parent/scss/settings/mixins/breakpoint-utilities";
@import "partials/colors";
@import "Parent/scss/settings/partials/color-utilities";
@import "Parent/scss/settings/mixins/fluid-properties";
@import "Parent/scss/settings/partials/accessibility";
@import "partials/general-variables";
@import "partials/typography";
@import "Parent/scss/settings/partials/typography-utlities";
@import "partials/grids";
@import "Parent/scss/settings/mixins/rem-calc";
@import "Parent/scss/settings/partials/sanitize";
/** * Parent and Core Style Imports * * Imports all core mixins and all template/component styles * * DO NOT EDIT OR REMOVE THESE DIRECTLY. * * Except for grids they can be commented out if they are not needed **/
/** * End Parent imports * **/
@font-face {
	font-family: 'Proxima Nova';
	src: url(../fonts/ProximaNova-Thin.eot);
	src: url(../fonts/ProximaNova-Thin.eot?#iefix) format('embedded-opentype'), url(../fonts/ProximaNova-Thin.svg#Brandon_reg) format('svg'), url(../fonts/ProximaNova-Thin.woff) format('woff'), url(../fonts/ProximaNova-Thin.ttf) format('truetype'), url(../fonts/ProximaNova-Thin.woff2) format('woff2');
	font-weight: 200;
}
@font-face {
	font-family: 'Proxima Nova';
	src: url(../fonts/ProximaNova-Light.eot);
	src: url(../fonts/ProximaNova-Light.eot?#iefix) format('embedded-opentype'), url(../fonts/ProximaNova-Light.svg#Brandon_reg) format('svg'), url(../fonts/ProximaNova-Light.woff) format('woff'), url(../fonts/ProximaNova-Light.ttf) format('truetype'), url(../fonts/ProximaNova-Light.woff2) format('woff2');
	font-weight: 300;
}
@font-face {
	font-family: 'Proxima Nova';
	src: url(../fonts/ProximaNova-Regular.eot);
	src: url(../fonts/ProximaNova-Regular.eot?#iefix) format('embedded-opentype'), url(../fonts/ProximaNova-Regular.svg#Brandon_reg) format('svg'), url(../fonts/ProximaNova-Regular.woff) format('woff'), url(../fonts/ProximaNova-Regular.ttf) format('truetype'), url(../fonts/ProximaNova-Regular.woff2) format('woff2');
	font-weight: 400;
}
@font-face {
	font-family: 'Proxima Nova';
	src: url(../fonts/ProximaNova-Bold.eot);
	src: url(../fonts/ProximaNova-Bold.eot?#iefix) format('embedded-opentype'), url(../fonts/ProximaNova-Bold.svg#Brandon_reg) format('svg'), url(../fonts/ProximaNova-Bold.woff) format('woff'), url(../fonts/ProximaNova-Bold.ttf) format('truetype'), url(../fonts/ProximaNova-Bold.woff2) format('woff2');
	font-weight: 700;
}
@font-face {
	font-family: 'Proxima Nova';
	src: url(../fonts/ProximaNova-Extrabold.eot);
	src: url(../fonts/ProximaNova-Extrabold.eot?#iefix) format('embedded-opentype'), url(../fonts/ProximaNova-Extrabold.svg#Brandon_reg) format('svg'), url(../fonts/ProximaNova-Extrabold.woff) format('woff'), url(../fonts/ProximaNova-Extrabold.ttf) format('truetype'), url(../fonts/ProximaNova-Extrabold.woff2) format('woff2');
	font-weight: 800;
}
@font-face {
	font-family: 'Proxima Nova';
	src: url(../fonts/ProximaNova-Black.eot);
	src: url(../fonts/ProximaNova-Black.eot?#iefix) format('embedded-opentype'), url(../fonts/ProximaNova-Black.svg#Brandon_reg) format('svg'), url(../fonts/ProximaNova-Black.woff) format('woff'), url(../fonts/ProximaNova-Black.ttf) format('truetype'), url(../fonts/ProximaNova-Black.woff2) format('woff2');
	font-weight: 900;
}
body {
	font-family: 'Proxima Nova', sans-serif;
	font-weight: 400;
	transition: all 0.9s ease-in-out;
}
.container {
	max-width: 1080px;
	margin: 0 auto;
	padding: 0 15px;
}
.row {
	display: flex;
	flex-wrap: wrap;
	align-content: flex-start;
	justify-content: space-between;
}
.col-3 {
	display: inherit;
	flex-wrap: wrap;
	width: calc(25% - 15px);
	
}
.col-4 {
	display: inherit;
	flex-wrap: wrap;
	width: 33.33%;
}
.col-8 {
	display: inherit;
	flex-wrap: wrap;
	width: 66.67%;
	align-items: center;
}
.col-6 {
	display: inherit;
	flex-wrap: wrap;
	width: 50%;
	align-items: center;
	align-content: center;
	background: transparent;
	overflow: visible;
}
.pt-50 {
	padding: 50px 20px 20px;
}
.default-btn {
	border: 2px solid #fff;
	color: #fff;
	display: inline-block;
	font-weight: 600;
	margin-top: 20px;
	padding: 10px 30px;
	text-decoration: none;
	border-radius: 4px;
	text-transform: uppercase;
	transition: all 0.9s ease-in-out;
	&:hover {
		background-color: #fff;
		color: #58b947;
		transition: all 0.9s ease-in-out;
	}
}
.button {
	border: 1px solid #58b947;
	background-color: #58b947;
	color: #fff;
	cursor: pointer;
	display: inline-block;
	font-weight: 400;
	font-size: 18px;
	margin: 20px 0;
	padding: 10px 30px;
	text-decoration: none;
	text-transform: uppercase;
	transition: all 0.9s ease-in-out;
	&:hover {
		background-color: #fff;
		color: #58b947;
		transition: all 0.9s ease-in-out;
	}
}
.home-btn {
	display:inline-block !important;
	width: 240px !important;
}
header {
	position: relative;
	.site-logo.faux-heading {
		position: absolute;
		top: -10px;
		a {
			display: block;
			img.custom-logo {
				height: 130px;
				width: auto;
			}
		}
	}
	.header-nav{ 
		.header-row{
			.header-row-inner-wrap{
				max-width: 1133px;
			}
		}	
	}
	.nav-cell {
		ul {
			li {
				a {
					color: #333;
					font-size: 14px;
				}
			}
			.is-active{
				a {
					color: #58b947;
				}
			}
		}
		.fancy-btn {
			display: flex !important;
			a {
				color: #fff;
				padding: 10px 30px;
				margin: 0 !important;
			}
			&:hover {
				a {
					color: #333;
					transition: all 0.9s ease-in-out;
				}
			}
		}
	}
}
.banner-section {
	height: 485px;
	position: relative;
	background-position: center center;
	background-size: cover;
	background-repeat: no-repeat;
	&:before {
		background: #00000052;
		bottom: 0;
		content: "";
		height: 100%;
		position: absolute;
		top: 0;
		width: 100%;
	}
	.banner-inner {
		position: absolute;
		left: 50%;
		top: 50%;
		transform: translate(-50%, -50%);
		text-align: center;
		width: 60%;
		h1 {
			color: #fff;
		}
	}
}
.text-box-section {
	padding: 50px 20px;

	.head-cont {
		background-color: #58b947;
		padding: 30px;
		width: 270px;

		h2 {
			color: #fff;
			font-size: 50px;
			line-height: 45px;
		}
	}
}
.img-box {
	margin: 0px;
	width: 100%;
	a {
		display: block;
		text-decoration: none;
	}
	img {
		width: 100%;
	}
	h3 {
		background-color: #333;
		color: #fff;
		font-size: 22px;
		line-height: 50px;
		text-align: center;
	}
	&:hover {
		h3 {
			color: #58b947;
			transition: all 0.9s ease-in-out;
		}
	}
}
.text-section {
	.section-title {
		position: relative;
		padding-left: 50px;
		font-size: 1.9rem;

		&::before {
			background-color: #58b947;
			content: "";
			position: absolute;
			top: 5px;
			left: 0;
			width: 30px;
			height: 30px;
		}
	}
	.section-content {
		p {
			font-size: 18px;
		}
	}
}
.brand-section {
	.brands-items {
		padding: 0 40px;
		.slick-arrow {
			background: url(http://metroaction.posturestage.com/wp-content/uploads/2023/07/Icon-ionic-ios-arrow-back.png) no-repeat;
			border: 0;
			cursor: pointer;
			font-size: 0;
			width: 30px;
			height: 30px;
			background-position: center center;
			left: 0;
			position: absolute;
			top: 50%;
			transform: translateY(-50%);
		}
		.slick-next.slick-arrow {
			transform: rotateY(180deg) translateY(-50%);
			left: auto;
			right: 0;
		}
		.slick-track {
			display: flex;
			align-items: center;
			.item {
				display: inherit;
				justify-content: center;
				padding: 0 20px;
			}
		}
	}
}
.footer-container {
	background: #f2f2f2 !important;
	.s-inner-wrap{
		max-width: 1080px;
	}
	.footer-logo {
		margin-bottom: 20px;
		img {
			width: 60%;
		}
	}
	.footer-widget-inner {
		font-size: 14px;
		line-height: 18px;
	}
	ul {
		margin: 0;
		li {
			font-size: 14px;
			line-height: 20px;
			margin-bottom: 13px;
		}
		.fancy-btn {
			background-color: transparent;
			padding: 0;
		}
	}
	ul.social-links {
		li {
			margin-top: 15px;
			a {
				color: #58b947 !important;
				font-size: 18px;
			}
		}
	}
	.subfooter-wrapper {
		background-color: transparent !important;
		.subfooter-content {
			flex-wrap: wrap;
			.copyright {
				font-size: 14px;
				font-weight: 500;
				width: 100%;
				display: block;
				text-align: center;
				margin-bottom: 5px;
			}
			.footer-bottom-menu {
				font-size: 14px;
				font-weight: 500;
				width: 100%;
				display: block;
				text-align: center;
				margin-bottom: 15px;

				p {
					margin: 0;
				}
			}
		}
	}
}
.page-about {
	.row {
		.img-box {
			margin: 0 20px 40px;
			h3 {
				background-color: transparent;
				text-align: left;
				color: #333;
				line-height: 1.2;
				margin: 20px 0 8px;
			}
			.content {
				font-weight: 500;
			}
		}
	}
	.text-section {
		.section-title {
			&::before {
				left: 0;
				opacity: 0.6;
			}
		}
	}	
	br {
		/*display:none;*/
	}
}
.inner-wrap {
	.title-green {
		position: relative;
		padding-left: 70px;
		&::before {
			background-color: #58b947;
			content: "";
			position: absolute;
			top: 6px;
			left: 0;
			width: 30px;
			height: 30px;
		}
	}
}
.cta-section {
	a.button.green {
		background: #58b947;
		border: 1px solid #58b947;
		color: #fff;
		&:hover {
			background: transparent;
			color: #58b947;
		}
	}
}
h4.wp-block-heading {
	max-width: 1080px;
	margin: 0 auto;
	padding: 20px 15px 0;
	font-size: 18px;
}
.wp-block-columns.is-layout-flex.wp-container-4 {
	max-width: 1080px;
	margin: 0 auto;
	padding: 20px 0;
	font-size: 18px;
	ul {
		padding: 0 15px;
		li {
			list-style: none;
			font-size: 16px;
			color: #333;
		}
	}
}
.page-contact-us {
	.entry-content {
		max-width: 1080px;
		margin: 0 auto;
		.text-section {
			.container {
				max-width: 100%;
			}
			
		}
		.wp-block-columns {
			.wp-block-column {
				&:first-child {
					padding: 50px 20px 20px;
				}
			}
		}
		.gform_wrapper {
			padding: 50px 20px 20px;
			form {
				label {
					font-size: 18px;
					font-weight: 600;
					position: relative;
					.gfield_required {
						.gfield_required_text {
							font-size: 0;
							display: inline-block;
							&:after {
								content: "*";
								position: absolute;
								top: 0;
								right: -20px;
								font-size: 20px;
								font-weight: 700;
								width: 20px;
								height: 20px;
								display: inline-block;
							}
						}
					}
				}
			}
		}
	}
}
.address-block {
	text-decoration: none;
	color: #333;
	font-weight: normal;
}
@media screen and (max-width: 1485px) {
	.text-box-section {
		.head-cont {
			h2 {
				font-size: 45px;
			}
		}
	}
	.banner-section {
		.banner-inner {
			h1 {
				font-size: 44px;
			}
		}
	}
}
@media screen and (max-width: 1380px) {
	.text-box-section {
		.head-cont {
			h2 {
				font-size: 30px;
				line-height: 1.3;
			}
		}
	}
	.banner-section {
		.banner-inner {
			h1 {
				font-size: 3.3vw;
			}
		}
	}
}
@media screen and (max-width: 1023px) {
	.container {
		max-width: 100%;
	}
	.col-3 {
		width: calc(50% - 10px);
	}
	.col-4 {
		width: 100%;
	}
	.col-6 {
		width: 100%;
	}
	.col-8 {
		width: 100%;
	}
	header {
		button[data-bm-close] {
			position: absolute;
			right: 0;
			top: 40px;
			svg {
				color: #333 !important;
			}
		}
		#navigation-modal {
			[data-bm-card] {
				background-color: #dae6d8;
				a {
					color: #333;
					font-size: calc(18px - 0.1vw);
				}
			}
		}
		#menu-main-menu{ 
			.is-active{ 
				a {
					color: #6ab944 !important;
				}
			}
		}
	}
	.text-section {
		.section-title {
			font-size: 22px;
			padding-left: 35px;
			&::before {
				top: 0;
				width: 20px;
				height: 20px;
				left: 0;
			}
		}
	}
	.text-box-section {
		.head-cont {
			width: 210px;
			h2 {
				font-size: 20px;
				line-height: 1.3;
			}
		}
		padding: 30px 20px;
	}
	.banner-section {
		height: 260px;
		.banner-inner {
			width: 92%;
			h1 {
				font-size: 22px;
			}
		}
	}
}
@media screen and (max-width: 767px) {
	.home {
		.col-3 {
			width: 100%;
		}
	}
	.brand-section {
		padding: 30px 15px 15px;
		.container {
			padding:0;
			.brands-items {
				padding: 10px;
				.slick-arrow{
					height:15px;
					width:15px;
				}
				.slick-track {
					.item {
						padding:0 8px;
					}
				}		
			}
		}
	}	
	.page-contact-us{
		.entry-content{
			.gform_wrapper {
				padding: 0px 20px 20px;
			}
		}	
	}
	.default-btn {
		font-size: 14px;
	}
	.img-box {
		margin: 0 25px 10px;
		h3 {
			font-size: 22px;
		}
	}
	.page-about {
		.partner-section{ 
			.text-section {
				padding: 30px 0 0;
			}
		}
		h4.wp-block-heading {
			padding: 0 30px 0;
		}
		.wp-block-columns.is-layout-flex.wp-container-4 {
			padding: 20px 30px;
			gap: 20px;
			ul {
				padding: 0;
				margin: 0;
			}
		}
		.cta-section{
			.col-6{
				justify-content:center;
			}
			a.button.green {
				margin-bottom: 45px;
			}
		}
		.row{ 
			.img-box {
				margin: 0 0 10px;
			}
		}
	}
	.page-contact-us {
		.text-section {
			padding: 30px 0 0;
		}
	}
	
		.footer-container{
			.subfooter-wrapper{
				margin-bottom: 10px;
				.subfooter-container{
					.copyright{
						font-size: 12px;
						margin-bottom: 13px;
					}
				}
			}
			ul {
				margin: 0 auto;
				width: 81%;
			}
		}
	footer {
		.f-grid {
			.cell {
				margin-bottom: 30px;
				padding: 0px 40px;
				&:last-child {
					margin-bottom: 0;
				}
				.footer-widget-inner{
					font-size: 14px;
					line-height: 18px;

					.wp-block-button{
						display: block;
						width: 100%;
					}
					.footer-logo{
						img{
							width: 90%;
						}
					}
					.menu-main-menu-container{
						.footer-container{
							ul {
								margin: 0 auto;
								width: 82%;
								a{
									color: #333;
								}
							}	
						}
					}
				}
			}
		}
	}
}
