#footer {
	background: $light-gray;
	position: relative;
	z-index: 2;
	.s-inner-wrap {
		padding-top: 25px;
		padding-bottom: 25px;
		width: 320px;

		@include mq(m) {
			width: 600px;
		}

		@include mq(l) {
			width: 1400px;
		}
	}
	.f-grid {
		--cell-size-sm: 100%;
		--cell-size-md: 50%;
		--cell-size-lg: 25%;
		justify-content: space-between;
		flex-flow: column nowrap;
		@include mq(m) {
			flex-flow: row wrap;
		}
		@include mq(l) {
			flex-flow: row nowrap;
		}

		--grid-gap: 0;

		@include mq(m) {
			--grid-gap: 15px;
		}

		.cell {
			flex-grow: 1;
			flex-shrink: 0;
		}
	}

	ul {
		padding-left: 0;
		list-style: none;

		a {
			color: $black;
			text-decoration: none;
			text-transform: uppercase;
			letter-spacing: 1px;
			font-weight: 700;
			&:hover,
			&:active,
			&:focus {
				color: $primary-color;
			}

			&:active {
				color: $secondary-color-dark;
			}
		}
	}

	.appointment-cell {
		padding-top: 1rem;
		width: 100%;
		@include mq(m) {
			width: auto;
		}

		.fancy-btn {
			width: 100%;
			align-self: stretch;
			align-items: center;
			justify-content: center;
			text-align: center;
			font-size: var(--fluid-body-sm);
			@include mq(m) {
				width: auto;
			}
			padding: 0.8em 1.4em;
		}
	}
	.media-header {
		margin-top: 1rem;
		font-weight: 600;
	}

	.links-cell {
		display: flex;
		flex-flow: row wrap;
		column-gap: 20px;
	}
	.media-wrap {
		display: flex;
		flex-flow: row wrap;
		justify-content: center;
		margin: 0 calc(-1 * var(--fluid-body));
		margin-top: 1rem;
		margin-bottom: 0.5rem;
		.media-link {
			.media-icon {
				display: block;
				padding: 0.5rem var(--fluid-body);
				svg {
					height: var(--fluid-h5);
				}
			}
		}
	}

	.logo-footer {
		margin-top: 0.75rem;
		padding-right: 0.5rem;

		img {
			width: 250px;
			max-width: 100%;
		}
	}

	.mobile-appointment {
		display: flex;

		@include mq(m) {
			display: none;
		}
	}

	.hide-mobile {
		display: none;

		@include mq(m) {
			display: flex;
		}
	}

	.contact-info {
		a {
			color: $primary-color;
			text-decoration: none;

			&:hover,
			&:active,
			&:focus {
				color: $primary-color-dark;
			}

			&:active {
				color: darken($primary-color-dark, 5%);
			}
		}
	}
	.subfooter-wrapper {
		background-color: $gray;
		width: 100%;

		.subfooter-content {
			display: flex;
			align-items: center;
			justify-content: center;

			a {
				color: $black;
				text-decoration: none;
				text-transform: uppercase;
				letter-spacing: 1px;
				font-weight: 700;
				&:hover,
				&:active,
				&:focus {
					color: $primary-color;
				}

				&:active {
					color: $secondary-color-dark;
				}
			}
		}
	}
}
