.page-header {
	position: relative;
	display: flex;
	flex-flow: column nowrap;
	justify-content: center;
	align-items: center;
	--max-width: 100%;

	.inner-wrap {
		display: flex;
		padding: 80px 20px;
		padding-bottom: 100px;
		align-self: center;
		max-width: 100%;
		width: $global-width;
		h1 {
			align-self: center;
			font-size: var(--fluid-h2);
			margin: 0 auto;
			//width: var(--max-width);
			//width: calc(var(--max-width) - 40px);
			max-width: 100%;
			color: white;
		}
	}

	.background-image {
		position: absolute;
		top: 0;
		left: 0;
		width: var(--max-width);
		max-width: 100%;
		height: 100%;
		z-index: -1;
		object-fit: cover;

		&:after {
			content: "";
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			@supports (backdrop-filter: brightness(0.5)) {
				backdrop-filter: brightness(0.5);
			}

			@supports not (backdrop-filter: brightness(0.5)) {
				background: rgba($black, 0.6);
			}
		}

		img {
			height: 100%;
			width: 100%;
			object-fit: cover;
			object-position: center center;
		}
	}
}
