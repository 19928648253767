.news-single {
	h1 {
		font-size: var(--fluid-h2);
	}
	.s-inner-wrap {
		padding-top: 25px;
		width: 60ch;
	}

	.post-date {
		font-size: var(--fluid-h6);
		color: $primary-color;
		font-weight: 700;
		margin-bottom: 0.5rem;
	}

	.post-navigation {
		display: flex;
		justify-content: space-between;
		width: 100%;

		margin-top: 2rem;
	}
}

.post-featured-section {
	.s-inner-wrap {
		padding-top: 20px;
	}
	.featured-image {
		max-height: 400px;
		min-height: 250px;
		width: auto;
		align-self: center;
	}
}
