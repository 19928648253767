/**
 * Buttons
 **/

.fancy-btn {
	display: flex;
	align-self: flex-start;
	width: max-content;
	padding: 0.8em 2.4em;
	font-weight: bold;
	background: $primary-color;
	border-radius: 4px;
	color: white;
	transition: all 0.3s ease;
	text-decoration: none;
	text-transform: uppercase;
	align-self: flex-start;

	// hover states
	&:hover,
	&:focus,
	&:active {
		color: white;
		background: $primary-color-dark;
	}

	// active state - click
	&:active {
		color: white;
		background: darken($primary-color-dark, 5%);
	}

	&.btn-ghost {
		background: transparent;
		color: white;
		border: 2px solid white;
		padding: calc(0.8em - 2px) calc(2.4em - 2px);

		&:hover,
		&:focus {
			background: rgba($white, 0.3);
		}

		&:active {
			color: $primary-color;
			background: rgba($white, 1);
		}
	}

	&.btn-ghost-dark {
		background: transparent;
		color: $black;
		border: 2px solid $black;
		padding: calc(0.8em - 2px) calc(2.4em - 2px);

		&:hover,
		&:focus {
			background: rgba($black, 0.2);
		}

		&:active {
			color: $primary-color;
			background: rgba($black, 0.4);
		}
	}
}

.wp-block-button {
	.wp-block-button__link {
		border-radius: 4px;
		font-size: var(--fluid-body);
		@extend .fancy-btn;
	}
}
