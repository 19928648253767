@charset "UTF-8";
/**
 * Parent and Core Style Imports
 *
 * Imports all core mixins and all template/component styles
 *
 * DO NO EDIT OR REMOVE THESE DIRECTLY.
 *
 **/
/**
 * Settings for sizing variables
 **/
/**
 * Can be used in various places like fluid typography
 * Must be called from styles ex: padding: var(--fluid-padding)
 * Very useful when styles looks too large on mobile
 **/
/**
 * Simplified Breakpoint mixin
 * Is Mobile-First but includes the modifier key to go up/down
 *
 * //TODO `Only` modifier seems logically complex maybe we don't need it?
 **/
/**
 * Fluid properties, makes life so much easier responsiveness wise
 * BIG WARNING THIS ONLY OPERATES IN Pixel units
 **/
/**
 * Fluid Properties
 * Allows properties to be linearly fluid between two viewport values.
 * This is useful for a variety of typography and responsive sizing needs
 *
 * @group Settings Mixins
 * @param {property} $property - A css property
 * @param {pixel value} $min-size - The smallest size this value will shrink to on small viewports
 * @param {pixel value} $max-size - The largest size this value will stretch to on large viewports
 * @param {pixel value} $min-vw - OPTIONAL: can set the min viewport for custom styling needs
 * @param {pixel value} $max-vw - OPTIONAL: can set the max viewport for custom styling needs
 **/
/**
 * Styling needed for accessibility
 **/
/**
 * General variables that can be used throughout the theme :)
 **/
/**
 * --- Font-face setters ---
 * Supports Local and external font foundries
 * Prefer using .woff files when using local fonts
 **/
@import url("https://use.typekit.net/myj2zro.css");
/** --- General typography settings --- **/
/**
 * General typography utilities
 **/
/*
@mixin general-typography-setter() {
	h1,
	h2,
	h3,
	h4,
	h5,
	h6 {
		font-weight: $header-font-weight;
		line-height: $header-line-height;
		margin-bottom: $header-margin-bottom;
		color: $header-color;
	}

	html {
		font-weight: $body-font-weight;
	}

	p {
		margin-bottom: $paragraph-margin-bottom;
	}
}*/
/**
 * --- Fluid Typography ---
 *
 * Creates fonts that will scale with the width of the screen using the function below
 * Works by using viewport width and some fancy linear algorithm that works with css calc!
 * Even IE11 works with this 🙌
 *
 * TODO Make this easier to edit
 **/
/** END --- Fluid Typography --- END **/
/** Blueprint css grid **/
:root {
  --fluid-section-padding: 30px;
}
@media (min-width: 400px) {
  :root {
    --fluid-section-padding: calc(
					30px + 20 *
						((100vw - 400px) / 800)
				);
  }
}
@media (min-width: 1200px) {
  :root {
    --fluid-section-padding: 50px;
  }
}

/**
 * Simple rem calculator takes pixels and turns them into rem units
 **/
/**
 * General style normalizer
 * If sanitizer isn't working well for you there are various other options:
 * normalize.css, reset.css, css-mini-reset, html5 reset stylesheet etc...
 **/
/** * Parent and Core Style Imports * * Imports all core mixins and all template/component styles * * DO NOT EDIT OR REMOVE THESE DIRECTLY. * * Except for grids they can be commented out if they are not needed **/
/** * End Parent imports * **/
@font-face {
  font-family: "Proxima Nova";
  src: url(../fonts/ProximaNova-Thin.eot);
  src: url(../fonts/ProximaNova-Thin.eot?#iefix) format("embedded-opentype"), url(../fonts/ProximaNova-Thin.svg#Brandon_reg) format("svg"), url(../fonts/ProximaNova-Thin.woff) format("woff"), url(../fonts/ProximaNova-Thin.ttf) format("truetype"), url(../fonts/ProximaNova-Thin.woff2) format("woff2");
  font-weight: 200;
}
@font-face {
  font-family: "Proxima Nova";
  src: url(../fonts/ProximaNova-Light.eot);
  src: url(../fonts/ProximaNova-Light.eot?#iefix) format("embedded-opentype"), url(../fonts/ProximaNova-Light.svg#Brandon_reg) format("svg"), url(../fonts/ProximaNova-Light.woff) format("woff"), url(../fonts/ProximaNova-Light.ttf) format("truetype"), url(../fonts/ProximaNova-Light.woff2) format("woff2");
  font-weight: 300;
}
@font-face {
  font-family: "Proxima Nova";
  src: url(../fonts/ProximaNova-Regular.eot);
  src: url(../fonts/ProximaNova-Regular.eot?#iefix) format("embedded-opentype"), url(../fonts/ProximaNova-Regular.svg#Brandon_reg) format("svg"), url(../fonts/ProximaNova-Regular.woff) format("woff"), url(../fonts/ProximaNova-Regular.ttf) format("truetype"), url(../fonts/ProximaNova-Regular.woff2) format("woff2");
  font-weight: 400;
}
@font-face {
  font-family: "Proxima Nova";
  src: url(../fonts/ProximaNova-Bold.eot);
  src: url(../fonts/ProximaNova-Bold.eot?#iefix) format("embedded-opentype"), url(../fonts/ProximaNova-Bold.svg#Brandon_reg) format("svg"), url(../fonts/ProximaNova-Bold.woff) format("woff"), url(../fonts/ProximaNova-Bold.ttf) format("truetype"), url(../fonts/ProximaNova-Bold.woff2) format("woff2");
  font-weight: 700;
}
@font-face {
  font-family: "Proxima Nova";
  src: url(../fonts/ProximaNova-Extrabold.eot);
  src: url(../fonts/ProximaNova-Extrabold.eot?#iefix) format("embedded-opentype"), url(../fonts/ProximaNova-Extrabold.svg#Brandon_reg) format("svg"), url(../fonts/ProximaNova-Extrabold.woff) format("woff"), url(../fonts/ProximaNova-Extrabold.ttf) format("truetype"), url(../fonts/ProximaNova-Extrabold.woff2) format("woff2");
  font-weight: 800;
}
@font-face {
  font-family: "Proxima Nova";
  src: url(../fonts/ProximaNova-Black.eot);
  src: url(../fonts/ProximaNova-Black.eot?#iefix) format("embedded-opentype"), url(../fonts/ProximaNova-Black.svg#Brandon_reg) format("svg"), url(../fonts/ProximaNova-Black.woff) format("woff"), url(../fonts/ProximaNova-Black.ttf) format("truetype"), url(../fonts/ProximaNova-Black.woff2) format("woff2");
  font-weight: 900;
}
body {
  font-family: "Proxima Nova", sans-serif;
  font-weight: 400;
  transition: all 0.9s ease-in-out;
}

.container {
  max-width: 1080px;
  margin: 0 auto;
  padding: 0 15px;
}

.row {
  display: flex;
  flex-wrap: wrap;
  align-content: flex-start;
  justify-content: space-between;
}

.col-3 {
  display: inherit;
  flex-wrap: wrap;
  width: calc(25% - 15px);
}

.col-4 {
  display: inherit;
  flex-wrap: wrap;
  width: 33.33%;
}

.col-8 {
  display: inherit;
  flex-wrap: wrap;
  width: 66.67%;
  align-items: center;
}

.col-6 {
  display: inherit;
  flex-wrap: wrap;
  width: 50%;
  align-items: center;
  align-content: center;
  background: transparent;
  overflow: visible;
}

.pt-50 {
  padding: 50px 20px 20px;
}

.default-btn {
  border: 2px solid #fff;
  color: #fff;
  display: inline-block;
  font-weight: 600;
  margin-top: 20px;
  padding: 10px 30px;
  text-decoration: none;
  border-radius: 4px;
  text-transform: uppercase;
  transition: all 0.9s ease-in-out;
}
.default-btn:hover {
  background-color: #fff;
  color: #58b947;
  transition: all 0.9s ease-in-out;
}

.button {
  border: 1px solid #58b947;
  background-color: #58b947;
  color: #fff;
  cursor: pointer;
  display: inline-block;
  font-weight: 400;
  font-size: 18px;
  margin: 20px 0;
  padding: 10px 30px;
  text-decoration: none;
  text-transform: uppercase;
  transition: all 0.9s ease-in-out;
}
.button:hover {
  background-color: #fff;
  color: #58b947;
  transition: all 0.9s ease-in-out;
}

.home-btn {
  display: inline-block !important;
  width: 240px !important;
}

header {
  position: relative;
}
header .site-logo.faux-heading {
  position: absolute;
  top: -10px;
}
header .site-logo.faux-heading a {
  display: block;
}
header .site-logo.faux-heading a img.custom-logo {
  height: 130px;
  width: auto;
}
header .header-nav .header-row .header-row-inner-wrap {
  max-width: 1133px;
}
header .nav-cell ul li a {
  color: #333;
  font-size: 14px;
}
header .nav-cell ul .is-active a {
  color: #58b947;
}
header .nav-cell .fancy-btn, header .nav-cell .fluentform form .ff-btn, .fluentform form header .nav-cell .ff-btn, header .nav-cell .wp-block-button .wp-block-button__link, .wp-block-button header .nav-cell .wp-block-button__link {
  display: flex !important;
}
header .nav-cell .fancy-btn a, header .nav-cell .fluentform form .ff-btn a, .fluentform form header .nav-cell .ff-btn a, header .nav-cell .wp-block-button .wp-block-button__link a, .wp-block-button header .nav-cell .wp-block-button__link a {
  color: #fff;
  padding: 10px 30px;
  margin: 0 !important;
}
header .nav-cell .fancy-btn:hover a, header .nav-cell .fluentform form .ff-btn:hover a, .fluentform form header .nav-cell .ff-btn:hover a, header .nav-cell .wp-block-button .wp-block-button__link:hover a, .wp-block-button header .nav-cell .wp-block-button__link:hover a {
  color: #333;
  transition: all 0.9s ease-in-out;
}

.banner-section {
  height: 485px;
  position: relative;
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;
}
.banner-section:before {
  background: #00000052;
  bottom: 0;
  content: "";
  height: 100%;
  position: absolute;
  top: 0;
  width: 100%;
}
.banner-section .banner-inner {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  width: 60%;
}
.banner-section .banner-inner h1 {
  color: #fff;
}

.text-box-section {
  padding: 50px 20px;
}
.text-box-section .head-cont {
  background-color: #58b947;
  padding: 30px;
  width: 270px;
}
.text-box-section .head-cont h2 {
  color: #fff;
  font-size: 50px;
  line-height: 45px;
}

.img-box {
  margin: 0px;
  width: 100%;
}
.img-box a {
  display: block;
  text-decoration: none;
}
.img-box img {
  width: 100%;
}
.img-box h3 {
  background-color: #333;
  color: #fff;
  font-size: 22px;
  line-height: 50px;
  text-align: center;
}
.img-box:hover h3 {
  color: #58b947;
  transition: all 0.9s ease-in-out;
}

.text-section .section-title {
  position: relative;
  padding-left: 50px;
  font-size: 1.9rem;
}
.text-section .section-title::before {
  background-color: #58b947;
  content: "";
  position: absolute;
  top: 5px;
  left: 0;
  width: 30px;
  height: 30px;
}
.text-section .section-content p {
  font-size: 18px;
}

.brand-section .brands-items {
  padding: 0 40px;
}
.brand-section .brands-items .slick-arrow {
  background: url(http://metroaction.posturestage.com/wp-content/uploads/2023/07/Icon-ionic-ios-arrow-back.png) no-repeat;
  border: 0;
  cursor: pointer;
  font-size: 0;
  width: 30px;
  height: 30px;
  background-position: center center;
  left: 0;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}
.brand-section .brands-items .slick-next.slick-arrow {
  transform: rotateY(180deg) translateY(-50%);
  left: auto;
  right: 0;
}
.brand-section .brands-items .slick-track {
  display: flex;
  align-items: center;
}
.brand-section .brands-items .slick-track .item {
  display: inherit;
  justify-content: center;
  padding: 0 20px;
}

.footer-container {
  background: #f2f2f2 !important;
}
.footer-container .s-inner-wrap {
  max-width: 1080px;
}
.footer-container .footer-logo {
  margin-bottom: 20px;
}
.footer-container .footer-logo img {
  width: 60%;
}
.footer-container .footer-widget-inner {
  font-size: 14px;
  line-height: 18px;
}
.footer-container ul {
  margin: 0;
}
.footer-container ul li {
  font-size: 14px;
  line-height: 20px;
  margin-bottom: 13px;
}
.footer-container ul .fancy-btn, .footer-container ul .fluentform form .ff-btn, .fluentform form .footer-container ul .ff-btn, .footer-container ul .wp-block-button .wp-block-button__link, .wp-block-button .footer-container ul .wp-block-button__link {
  background-color: transparent;
  padding: 0;
}
.footer-container ul.social-links li {
  margin-top: 15px;
}
.footer-container ul.social-links li a {
  color: #58b947 !important;
  font-size: 18px;
}
.footer-container .subfooter-wrapper {
  background-color: transparent !important;
}
.footer-container .subfooter-wrapper .subfooter-content {
  flex-wrap: wrap;
}
.footer-container .subfooter-wrapper .subfooter-content .copyright {
  font-size: 14px;
  font-weight: 500;
  width: 100%;
  display: block;
  text-align: center;
  margin-bottom: 5px;
}
.footer-container .subfooter-wrapper .subfooter-content .footer-bottom-menu {
  font-size: 14px;
  font-weight: 500;
  width: 100%;
  display: block;
  text-align: center;
  margin-bottom: 15px;
}
.footer-container .subfooter-wrapper .subfooter-content .footer-bottom-menu p {
  margin: 0;
}

.page-about .row .img-box {
  margin: 0 20px 40px;
}
.page-about .row .img-box h3 {
  background-color: transparent;
  text-align: left;
  color: #333;
  line-height: 1.2;
  margin: 20px 0 8px;
}
.page-about .row .img-box .content {
  font-weight: 500;
}
.page-about .text-section .section-title::before {
  left: 0;
  opacity: 0.6;
}
.page-about br {
  /*display:none;*/
}

.inner-wrap .title-green {
  position: relative;
  padding-left: 70px;
}
.inner-wrap .title-green::before {
  background-color: #58b947;
  content: "";
  position: absolute;
  top: 6px;
  left: 0;
  width: 30px;
  height: 30px;
}

.cta-section a.button.green {
  background: #58b947;
  border: 1px solid #58b947;
  color: #fff;
}
.cta-section a.button.green:hover {
  background: transparent;
  color: #58b947;
}

h4.wp-block-heading {
  max-width: 1080px;
  margin: 0 auto;
  padding: 20px 15px 0;
  font-size: 18px;
}

.wp-block-columns.is-layout-flex.wp-container-4 {
  max-width: 1080px;
  margin: 0 auto;
  padding: 20px 0;
  font-size: 18px;
}
.wp-block-columns.is-layout-flex.wp-container-4 ul {
  padding: 0 15px;
}
.wp-block-columns.is-layout-flex.wp-container-4 ul li {
  list-style: none;
  font-size: 16px;
  color: #333;
}

.page-contact-us .entry-content {
  max-width: 1080px;
  margin: 0 auto;
}
.page-contact-us .entry-content .text-section .container {
  max-width: 100%;
}
.page-contact-us .entry-content .wp-block-columns .wp-block-column:first-child {
  padding: 50px 20px 20px;
}
.page-contact-us .entry-content .gform_wrapper {
  padding: 50px 20px 20px;
}
.page-contact-us .entry-content .gform_wrapper form label {
  font-size: 18px;
  font-weight: 600;
  position: relative;
}
.page-contact-us .entry-content .gform_wrapper form label .gfield_required .gfield_required_text {
  font-size: 0;
  display: inline-block;
}
.page-contact-us .entry-content .gform_wrapper form label .gfield_required .gfield_required_text:after {
  content: "*";
  position: absolute;
  top: 0;
  right: -20px;
  font-size: 20px;
  font-weight: 700;
  width: 20px;
  height: 20px;
  display: inline-block;
}

.address-block {
  text-decoration: none;
  color: #333;
  font-weight: normal;
}

@media screen and (max-width: 1485px) {
  .text-box-section .head-cont h2 {
    font-size: 45px;
  }

  .banner-section .banner-inner h1 {
    font-size: 44px;
  }
}
@media screen and (max-width: 1380px) {
  .text-box-section .head-cont h2 {
    font-size: 30px;
    line-height: 1.3;
  }

  .banner-section .banner-inner h1 {
    font-size: 3.3vw;
  }
}
@media screen and (max-width: 1023px) {
  .container {
    max-width: 100%;
  }

  .col-3 {
    width: calc(50% - 10px);
  }

  .col-4 {
    width: 100%;
  }

  .col-6 {
    width: 100%;
  }

  .col-8 {
    width: 100%;
  }

  header button[data-bm-close] {
    position: absolute;
    right: 0;
    top: 40px;
  }
  header button[data-bm-close] svg {
    color: #333 !important;
  }
  header #navigation-modal [data-bm-card] {
    background-color: #dae6d8;
  }
  header #navigation-modal [data-bm-card] a {
    color: #333;
    font-size: calc(18px - 0.1vw);
  }
  header #menu-main-menu .is-active a {
    color: #6ab944 !important;
  }

  .text-section .section-title {
    font-size: 22px;
    padding-left: 35px;
  }
  .text-section .section-title::before {
    top: 0;
    width: 20px;
    height: 20px;
    left: 0;
  }

  .text-box-section {
    padding: 30px 20px;
  }
  .text-box-section .head-cont {
    width: 210px;
  }
  .text-box-section .head-cont h2 {
    font-size: 20px;
    line-height: 1.3;
  }

  .banner-section {
    height: 260px;
  }
  .banner-section .banner-inner {
    width: 92%;
  }
  .banner-section .banner-inner h1 {
    font-size: 22px;
  }
}
@media screen and (max-width: 767px) {
  .home .col-3 {
    width: 100%;
  }

  .brand-section {
    padding: 30px 15px 15px;
  }
  .brand-section .container {
    padding: 0;
  }
  .brand-section .container .brands-items {
    padding: 10px;
  }
  .brand-section .container .brands-items .slick-arrow {
    height: 15px;
    width: 15px;
  }
  .brand-section .container .brands-items .slick-track .item {
    padding: 0 8px;
  }

  .page-contact-us .entry-content .gform_wrapper {
    padding: 0px 20px 20px;
  }

  .default-btn {
    font-size: 14px;
  }

  .img-box {
    margin: 0 25px 10px;
  }
  .img-box h3 {
    font-size: 22px;
  }

  .page-about .partner-section .text-section {
    padding: 30px 0 0;
  }
  .page-about h4.wp-block-heading {
    padding: 0 30px 0;
  }
  .page-about .wp-block-columns.is-layout-flex.wp-container-4 {
    padding: 20px 30px;
    gap: 20px;
  }
  .page-about .wp-block-columns.is-layout-flex.wp-container-4 ul {
    padding: 0;
    margin: 0;
  }
  .page-about .cta-section .col-6 {
    justify-content: center;
  }
  .page-about .cta-section a.button.green {
    margin-bottom: 45px;
  }
  .page-about .row .img-box {
    margin: 0 0 10px;
  }

  .page-contact-us .text-section {
    padding: 30px 0 0;
  }

  .footer-container .subfooter-wrapper {
    margin-bottom: 10px;
  }
  .footer-container .subfooter-wrapper .subfooter-container .copyright {
    font-size: 12px;
    margin-bottom: 13px;
  }
  .footer-container ul {
    margin: 0 auto;
    width: 81%;
  }

  footer .f-grid .cell {
    margin-bottom: 30px;
    padding: 0px 40px;
  }
  footer .f-grid .cell:last-child {
    margin-bottom: 0;
  }
  footer .f-grid .cell .footer-widget-inner {
    font-size: 14px;
    line-height: 18px;
  }
  footer .f-grid .cell .footer-widget-inner .wp-block-button {
    display: block;
    width: 100%;
  }
  footer .f-grid .cell .footer-widget-inner .footer-logo img {
    width: 90%;
  }
  footer .f-grid .cell .footer-widget-inner .menu-main-menu-container .footer-container ul {
    margin: 0 auto;
    width: 82%;
  }
  footer .f-grid .cell .footer-widget-inner .menu-main-menu-container .footer-container ul a {
    color: #333;
  }
}
/* Document
 * ========================================================================== */
/**
 * Add border box sizing in all browsers (opinionated).
 */
*,
::before,
::after {
  box-sizing: border-box;
}

/**
 * 1. Add text decoration inheritance in all browsers (opinionated).
 * 2. Add vertical alignment inheritance in all browsers (opinionated).
 */
::before,
::after {
  text-decoration: inherit;
  /* 1 */
  vertical-align: inherit;
  /* 2 */
}

/**
 * 1. Use the default cursor in all browsers (opinionated).
 * 2. Change the line height in all browsers (opinionated).
 * 3. Use a 4-space tab width in all browsers (opinionated).
 * 4. Remove the grey highlight on links in iOS (opinionated).
 * 5. Prevent adjustments of font size after orientation changes in
 *    IE on Windows Phone and in iOS.
 * 6. Breaks words to prevent overflow in all browsers (opinionated).
 */
html {
  cursor: default;
  /* 1 */
  line-height: 1.5;
  /* 2 */
  -moz-tab-size: 4;
  /* 3 */
  tab-size: 4;
  /* 3 */
  -webkit-tap-highlight-color: transparent;
  -ms-text-size-adjust: 100%;
  /* 5 */
  -webkit-text-size-adjust: 100%;
  /* 5 */
  word-break: break-word;
  /* 6 */
}

/* Sections
 * ========================================================================== */
/**
 * Remove the margin in all browsers (opinionated).
 */
body {
  margin: 0;
}

/**
 * Correct margin-top nobody ever wants margin-top
 */
h1, h2, h3, h4, h5, h6 {
  margin-top: 0;
}

/* Grouping content
 * ========================================================================== */
/**
 * Remove the margin on nested lists in Chrome, Edge, IE, and Safari.
 */
dl dl,
dl ol,
dl ul,
ol dl,
ul dl {
  margin: 0;
}

/**
 * Remove the margin on nested lists in Edge 18- and IE.
 */
ol ol,
ol ul,
ul ol,
ul ul {
  margin: 0;
}

/**
 * 1. Add the correct sizing in Firefox.
 * 2. Show the overflow in Edge 18- and IE.
 */
hr {
  height: 0;
  /* 1 */
  overflow: visible;
  /* 2 */
}

/**
 * Add the correct display in IE.
 */
main {
  display: block;
}

/**
 * Remove the list style on navigation lists in all browsers (opinionated).
 */
nav ol,
nav ul {
  list-style: none;
  padding: 0;
}

/**
 * 1. Correct the inheritance and scaling of font size in all browsers.
 * 2. Correct the odd `em` font sizing in all browsers.
 */
pre {
  font-family: monospace, monospace;
  /* 1 */
  font-size: 1em;
  /* 2 */
}

/* Text-level semantics
 * ========================================================================== */
/**
 * Remove the gray background on active links in IE 10.
 */
a {
  background-color: transparent;
}

/**
 * Add the correct text decoration in Edge 18-, IE, and Safari.
 */
abbr[title] {
  text-decoration: underline;
  text-decoration: underline dotted;
}

/**
 * Add the correct font weight in Chrome, Edge, and Safari.
 */
b,
strong {
  font-weight: bolder;
}

/**
 * 1. Correct the inheritance and scaling of font size in all browsers.
 * 2. Correct the odd `em` font sizing in all browsers.
 */
code,
kbd,
samp {
  font-family: monospace, monospace;
  /* 1 */
  font-size: 1em;
  /* 2 */
}

/**
 * Add the correct font size in all browsers.
 */
small {
  font-size: 80%;
}

/* Embedded content
 * ========================================================================== */
/*
 * Change the alignment on media elements in all browsers (opinionated).
 */
audio,
canvas,
iframe,
img,
svg,
video {
  vertical-align: middle;
}

/**
 * Add the correct display in IE 9-.
 */
audio,
video {
  display: inline-block;
}

/**
 * Add the correct display in iOS 4-7.
 */
audio:not([controls]) {
  display: none;
  height: 0;
}

/**
 * Remove the border on iframes in all browsers (opinionated).
 */
iframe {
  border-style: none;
}

/**
 * Remove the border on images within links in IE 10-.
 */
img {
  border-style: none;
}

/**
 * Default that images won't stretch outside of the parent container
 */
img {
  max-width: 100%;
}

/**
 * Change the fill color to match the text color in all browsers (opinionated).
 */
svg:not([fill]) {
  fill: currentColor;
}

/**
 * Hide the overflow in IE.
 */
svg:not(:root) {
  overflow: hidden;
}

/* Tabular data
 * ========================================================================== */
/**
 * Collapse border spacing in all browsers (opinionated).
 */
table {
  border-collapse: collapse;
}

/* Forms
 * ========================================================================== */
/**
 * Remove the margin on controls in Safari.
 */
button,
input,
select {
  margin: 0;
}

/**
 * 1. Show the overflow in IE.
 * 2. Remove the inheritance of text transform in Edge 18-, Firefox, and IE.
 */
button {
  overflow: visible;
  /* 1 */
  text-transform: none;
  /* 2 */
}

/**
 * Correct the inability to style buttons in iOS and Safari.
 */
button,
[type=button],
[type=reset],
[type=submit] {
  -webkit-appearance: button;
}

/**
 * 1. Change the inconsistent appearance in all browsers (opinionated).
 * 2. Correct the padding in Firefox.
 */
fieldset {
  border: 1px solid #a0a0a0;
  /* 1 */
  padding: 0.35em 0.75em 0.625em;
  /* 2 */
}

/**
 * Show the overflow in Edge 18- and IE.
 */
input {
  overflow: visible;
}

/**
 * 1. Correct the text wrapping in Edge 18- and IE.
 * 2. Correct the color inheritance from `fieldset` elements in IE.
 */
legend {
  color: inherit;
  /* 2 */
  display: table;
  /* 1 */
  max-width: 100%;
  /* 1 */
  white-space: normal;
  /* 1 */
}

/**
 * 1. Add the correct display in Edge 18- and IE.
 * 2. Add the correct vertical alignment in Chrome, Edge, and Firefox.
 */
progress {
  display: inline-block;
  /* 1 */
  vertical-align: baseline;
  /* 2 */
}

/**
 * Remove the inheritance of text transform in Firefox.
 */
select {
  text-transform: none;
}

/**
 * 1. Remove the margin in Firefox and Safari.
 * 2. Remove the default vertical scrollbar in IE.
 * 3. Change the resize direction in all browsers (opinionated).
 */
textarea {
  margin: 0;
  /* 1 */
  overflow: auto;
  /* 2 */
  resize: vertical;
  /* 3 */
}

/**
 * Remove the padding in IE 10-.
 */
[type=checkbox],
[type=radio] {
  padding: 0;
}

/**
 * 1. Correct the odd appearance in Chrome, Edge, and Safari.
 * 2. Correct the outline style in Safari.
 */
[type=search] {
  -webkit-appearance: textfield;
  /* 1 */
  outline-offset: -2px;
  /* 2 */
}

/**
 * Correct the cursor style of increment and decrement buttons in Safari.
 */
::-webkit-inner-spin-button,
::-webkit-outer-spin-button {
  height: auto;
}

/**
 * Correct the text style of placeholders in Chrome, Edge, and Safari.
 */
::-webkit-input-placeholder {
  color: inherit;
  opacity: 0.54;
}

/**
 * Remove the inner padding in Chrome, Edge, and Safari on macOS.
 */
::-webkit-search-decoration {
  -webkit-appearance: none;
}

/**
 * 1. Correct the inability to style upload buttons in iOS and Safari.
 * 2. Change font properties to `inherit` in Safari.
 */
::-webkit-file-upload-button {
  -webkit-appearance: button;
  /* 1 */
  font: inherit;
  /* 2 */
}

/**
 * Remove the inner border and padding of focus outlines in Firefox.
 */
::-moz-focus-inner {
  border-style: none;
  padding: 0;
}

/**
 * Restore the focus outline styles unset by the previous rule in Firefox.
 */
:-moz-focusring {
  outline: 1px dotted ButtonText;
}

/**
 * Remove the additional :invalid styles in Firefox.
 */
:-moz-ui-invalid {
  box-shadow: none;
}

/* Interactive
 * ========================================================================== */
/*
 * Add the correct display in Edge 18- and IE.
 */
details {
  display: block;
}

/*
 * Add the correct styles in Edge 18-, IE, and Safari.
 */
dialog {
  background-color: white;
  border: solid;
  color: black;
  display: block;
  height: -moz-fit-content;
  height: -webkit-fit-content;
  height: fit-content;
  left: 0;
  margin: auto;
  padding: 1em;
  position: absolute;
  right: 0;
  width: -moz-fit-content;
  width: -webkit-fit-content;
  width: fit-content;
}

dialog:not([open]) {
  display: none;
}

/*
 * Add the correct display in all browsers.
 */
summary {
  display: list-item;
}

/* Scripting
 * ========================================================================== */
/**
 * Add the correct display in IE 9-.
 */
canvas {
  display: inline-block;
}

/**
 * Add the correct display in IE.
 */
template {
  display: none;
}

/* User interaction
 * ========================================================================== */
/*
 * 1. Remove the tapping delay in IE 10.
 * 2. Remove the tapping delay on clickable elements
	  in all browsers (opinionated).
 */
a,
area,
button,
input,
label,
select,
summary,
textarea,
[tabindex] {
  -ms-touch-action: manipulation;
  /* 1 */
  touch-action: manipulation;
  /* 2 */
}

/**
 * Add the correct display in IE 10-.
 */
[hidden] {
  display: none;
}

/* Accessibility
 * ========================================================================== */
/**
 * Change the cursor on busy elements in all browsers (opinionated).
 */
[aria-busy=true] {
  cursor: progress;
}

/*
 * Change the cursor on control elements in all browsers (opinionated).
 */
[aria-controls] {
  cursor: pointer;
}

/*
 * Change the cursor on disabled, not-editable, or otherwise
 * inoperable elements in all browsers (opinionated).
 */
[aria-disabled=true],
[disabled] {
  cursor: not-allowed;
}

/*
 * Change the display on visually hidden accessible elements
 * in all browsers (opinionated).
 */
[aria-hidden=false][hidden] {
  display: initial;
}

[aria-hidden=false][hidden]:not(:focus) {
  clip: rect(0, 0, 0, 0);
  position: absolute;
}

h1, h2, h3, h4, h5, h6 {
  font-family: "proxima-nova", -apple-system, "Ubuntu", sans-serif;
  font-weight: 700;
  line-height: 1.1;
  margin-bottom: 0.5rem;
  color: #333333;
  text-transform: none;
}

html {
  font-weight: 400;
  font-family: "proxima-nova", -apple-system, "Ubuntu", sans-serif;
  line-height: 1.6;
  color: #333333;
}

p {
  margin: 1rem 0;
}

* {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-smooth: always;
}

:root {
  --fluid-jumbo: 55px;
}
@media (min-width: 400px) {
  :root {
    --fluid-jumbo: calc(
					55px + 20 *
						((100vw - 400px) / 800)
				);
  }
}
@media (min-width: 1200px) {
  :root {
    --fluid-jumbo: 75px;
  }
}
:root {
  --fluid-h1: 40px;
}
@media (min-width: 400px) {
  :root {
    --fluid-h1: calc(
					40px + 12 *
						((100vw - 400px) / 800)
				);
  }
}
@media (min-width: 1200px) {
  :root {
    --fluid-h1: 52px;
  }
}
:root {
  --fluid-h2: 30px;
}
@media (min-width: 400px) {
  :root {
    --fluid-h2: calc(
					30px + 10 *
						((100vw - 400px) / 800)
				);
  }
}
@media (min-width: 1200px) {
  :root {
    --fluid-h2: 40px;
  }
}
:root {
  --fluid-h3: 28px;
}
@media (min-width: 400px) {
  :root {
    --fluid-h3: calc(
					28px + 8 *
						((100vw - 400px) / 800)
				);
  }
}
@media (min-width: 1200px) {
  :root {
    --fluid-h3: 36px;
  }
}
:root {
  --fluid-h4: 24px;
}
@media (min-width: 400px) {
  :root {
    --fluid-h4: calc(
					24px + 8 *
						((100vw - 400px) / 800)
				);
  }
}
@media (min-width: 1200px) {
  :root {
    --fluid-h4: 32px;
  }
}
:root {
  --fluid-h5: 22px;
}
@media (min-width: 400px) {
  :root {
    --fluid-h5: calc(
					22px + 6 *
						((100vw - 400px) / 800)
				);
  }
}
@media (min-width: 1200px) {
  :root {
    --fluid-h5: 28px;
  }
}
:root {
  --fluid-h6: 20px;
}
@media (min-width: 400px) {
  :root {
    --fluid-h6: calc(
					20px + 4 *
						((100vw - 400px) / 800)
				);
  }
}
@media (min-width: 1200px) {
  :root {
    --fluid-h6: 24px;
  }
}
:root {
  --fluid-body: 16px;
}
@media (min-width: 400px) {
  :root {
    --fluid-body: calc(
					16px + 2 *
						((100vw - 400px) / 800)
				);
  }
}
@media (min-width: 1200px) {
  :root {
    --fluid-body: 18px;
  }
}
:root {
  --fluid-body-sm: 14px;
}
@media (min-width: 400px) {
  :root {
    --fluid-body-sm: calc(
					14px + 2 *
						((100vw - 400px) / 800)
				);
  }
}
@media (min-width: 1200px) {
  :root {
    --fluid-body-sm: 16px;
  }
}
:root {
  --fluid-body-xs: 12px;
}
@media (min-width: 400px) {
  :root {
    --fluid-body-xs: calc(
					12px + 2 *
						((100vw - 400px) / 800)
				);
  }
}
@media (min-width: 1200px) {
  :root {
    --fluid-body-xs: 14px;
  }
}

h1,
.fluid-h1 {
  font-size: var(--fluid-h1);
}

h2,
.fluid-h2 {
  font-size: var(--fluid-h2);
}

h3,
.fluid-h3 {
  font-size: var(--fluid-h3);
}

h4,
.fluid-h4 {
  font-size: var(--fluid-h4);
}

h5,
.fluid-h5 {
  font-size: var(--fluid-h5);
}

h6,
.fluid-h6 {
  font-size: var(--fluid-h6);
}

html,
.fluid-body {
  font-size: var(--fluid-body);
}

.fluid-body-sm {
  font-size: var(--fluid-body-sm);
}

/**
 * Supers!
 * An example of a separate functionality module
 * To note this module integrates with the theme settings
 * This should be a normal aspect of modules for easy integration with existing projects
 **/
/**
 * Generic footer layout engine 📏
 **/
.s-footer {
  display: flex;
  flex-flow: column nowrap;
  background-color: #707070;
}
.s-footer .s-footer-inner-wrap {
  display: flex;
  flex-flow: column nowrap;
  max-width: 1200px;
  margin-left: auto;
  margin-right: auto;
  width: 100%;
  padding: 1rem 1rem 1rem;
}
.s-footer .s-footer-grid {
  display: flex;
  flex-flow: row wrap;
  align-items: flex-start;
  justify-content: center;
  width: 230px;
  margin-left: auto;
  margin-right: auto;
}
@media (min-width: 768px) {
  .s-footer .s-footer-grid {
    width: 100%;
    justify-content: space-between;
  }
}
.s-footer .s-footer-cell {
  padding: 0.6rem 0.6rem;
  display: block;
  max-width: 100%;
  width: 230px;
}
@media (min-width: 768px) {
  .s-footer .s-footer-cell {
    width: auto;
  }
}
.s-footer .s-footer-logo-container .s-footer-logo {
  width: 100%;
  max-width: 100%;
  max-height: 60px;
  height: 50px;
}
@media (min-width: 768px) {
  .s-footer .s-footer-logo-container {
    width: auto;
  }
}
.s-footer .s-footer-menu {
  width: 230px;
}
@media (min-width: 768px) {
  .s-footer .s-footer-menu {
    width: auto;
  }
}
.s-footer .s-footer-menu ul {
  display: flex;
  flex-flow: column nowrap;
  list-style: none;
  width: 100%;
}
@media (min-width: 768px) {
  .s-footer .s-footer-menu ul {
    margin-left: -1rem;
  }
}
.s-footer .s-footer-menu ul li {
  width: auto;
  font-weight: bold;
  text-align: center;
}
.s-footer .s-footer-menu ul li a {
  padding-top: 0.6rem;
  padding-bottom: 0.6rem;
}
@media (min-width: 768px) {
  .s-footer .s-footer-menu ul {
    flex-flow: row nowrap;
  }
}
.s-footer .s-footer-copyright {
  text-align: center;
}

.s-footer-copyright-row {
  background-color: #d1d1d1;
  color: white;
}
.s-footer-copyright-row a {
  color: white;
  transition: all 0.3s ease;
}
.s-footer-copyright-row a:hover, .s-footer-copyright-row a:active, .s-footer-copyright-row a:focus {
  color: orange;
}
.s-footer-copyright-row i {
  padding: 0.3rem;
}
.s-footer-copyright-row .inner-wrap {
  display: flex;
  flex-flow: column nowrap;
  max-width: 1200px;
  margin-left: auto;
  margin-right: auto;
  width: 100%;
  padding: 20px 1rem 20px;
  justify-content: center;
  align-items: center;
}
@media (min-width: 480px) {
  .s-footer-copyright-row .inner-wrap {
    flex-flow: row nowrap;
    justify-content: space-between;
  }
}
.s-footer-copyright-row .right-cell {
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;
}
@media (min-width: 768px) {
  .s-footer-copyright-row .right-cell {
    flex-flow: row nowrap;
  }
}
.s-footer-copyright-row .right-cell .foundation-link {
  display: flex;
  margin: 1rem;
  padding-top: 1rem;
  margin-top: 0;
  text-decoration: underline;
  font-weight: 500;
}
@media (min-width: 480px) {
  .s-footer-copyright-row .right-cell .foundation-link {
    padding-top: unset;
    margin: unset;
    margin-right: 1rem;
  }
}
.s-footer-copyright-row .right-cell .footer-socials {
  display: flex;
  justify-content: center;
  margin-top: 0.5rem;
}
@media (min-width: 768px) {
  .s-footer-copyright-row .right-cell .footer-socials {
    margin-top: 0;
  }
}

.s-footer-menu a {
  color: orange;
  font-weight: 500;
}
.s-footer-menu a:hover, .s-footer-menu a:active, .s-footer-menu a:focus {
  filter: brightness(110%);
}
.s-footer-menu .is-active a {
  background: transparent;
  font-weight: 700;
  color: #b0cbac;
}

@media (max-width: 1024px) {
  .s-footer-grid {
    justify-content: center;
  }
  .s-footer-grid .menu {
    justify-content: center;
  }
}
.s-footer-grid .menu-item {
  flex-shrink: 0;
}

/**
 * Super section :D
 * A section that contains content
 * The section/inner-wrap is set to flex column for easy placement of children elements 🙌
 **/
.s-section {
  display: flex;
  flex-flow: column nowrap;
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;
  width: 100%;
  max-width: 100%;
}
.s-inner-wrap {
  display: flex;
  flex-flow: column nowrap;
  padding: var(--fluid-section-padding) 20px;
  width: 1200px;
  max-width: 100%;
  margin-left: auto;
  margin-right: auto;
  justify-self: stretch;
  align-self: stretch;
  overflow: hidden;
  z-index: 1;
  flex-grow: 1;
}
.s-center > .super-inner-wrap,
.s-center .s-inner-wrap {
  align-items: center;
  justify-content: center;
}
.s-flex-end > .super-inner-wrap,
.s-flex-end .s-inner-wrap {
  justify-content: flex-end;
}
.s-constrain .super-inner-wrap,
.s-constrain .s-inner-wrap {
  width: 1200px;
}

/**
 * Buttons
 **/
.fancy-btn, .fluentform form .ff-btn, .wp-block-button .wp-block-button__link {
  display: flex;
  align-self: flex-start;
  width: max-content;
  padding: 0.8em 2.4em;
  font-weight: bold;
  background: #58b947;
  border-radius: 4px;
  color: white;
  transition: all 0.3s ease;
  text-decoration: none;
  text-transform: uppercase;
  align-self: flex-start;
}
.fancy-btn:hover, .fluentform form .ff-btn:hover, .wp-block-button .wp-block-button__link:hover, .fancy-btn:focus, .fluentform form .ff-btn:focus, .wp-block-button .wp-block-button__link:focus, .fancy-btn:active, .fluentform form .ff-btn:active, .wp-block-button .wp-block-button__link:active {
  color: white;
  background: #4b9e3c;
}
.fancy-btn:active, .fluentform form .ff-btn:active, .wp-block-button .wp-block-button__link:active {
  color: white;
  background: #428b35;
}
.fancy-btn.btn-ghost, .fluentform form .btn-ghost.ff-btn, .wp-block-button .btn-ghost.wp-block-button__link {
  background: transparent;
  color: white;
  border: 2px solid white;
  padding: calc(0.8em - 2px) calc(2.4em - 2px);
}
.fancy-btn.btn-ghost:hover, .fluentform form .btn-ghost.ff-btn:hover, .wp-block-button .btn-ghost.wp-block-button__link:hover, .fancy-btn.btn-ghost:focus, .fluentform form .btn-ghost.ff-btn:focus, .wp-block-button .btn-ghost.wp-block-button__link:focus {
  background: rgba(255, 255, 255, 0.3);
}
.fancy-btn.btn-ghost:active, .fluentform form .btn-ghost.ff-btn:active, .wp-block-button .btn-ghost.wp-block-button__link:active {
  color: #58b947;
  background: white;
}
.fancy-btn.btn-ghost-dark, .fluentform form .btn-ghost-dark.ff-btn, .wp-block-button .btn-ghost-dark.wp-block-button__link {
  background: transparent;
  color: #333333;
  border: 2px solid #333333;
  padding: calc(0.8em - 2px) calc(2.4em - 2px);
}
.fancy-btn.btn-ghost-dark:hover, .fluentform form .btn-ghost-dark.ff-btn:hover, .wp-block-button .btn-ghost-dark.wp-block-button__link:hover, .fancy-btn.btn-ghost-dark:focus, .fluentform form .btn-ghost-dark.ff-btn:focus, .wp-block-button .btn-ghost-dark.wp-block-button__link:focus {
  background: rgba(51, 51, 51, 0.2);
}
.fancy-btn.btn-ghost-dark:active, .fluentform form .btn-ghost-dark.ff-btn:active, .wp-block-button .btn-ghost-dark.wp-block-button__link:active {
  color: #58b947;
  background: rgba(51, 51, 51, 0.4);
}

.wp-block-button .wp-block-button__link {
  border-radius: 4px;
  font-size: var(--fluid-body);
}

/**
 * Link styling
 * Links can get pretty fancy so the setting partials do not get involved
 **/
a {
  color: #58b947;
  font-weight: bold;
  transition: all 0.3s ease;
}
a:hover, a:focus {
  color: #4b9e3c;
}
a:active {
  color: #428b35;
}

.fancy-link {
  position: relative;
  text-decoration: none;
  padding-bottom: 5px;
  font-weight: 700;
}
.fancy-link:after {
  content: "";
  position: absolute;
  bottom: 2px;
  left: 0;
  width: 100%;
  height: 5px;
  background-color: currentColor;
}

.header-nav {
  position: relative;
  display: flex;
  flex-flow: column nowrap;
  width: 100%;
  z-index: 100;
  background-color: #ffffff;
  box-shadow: 5px 5px 10px rgba(51, 51, 51, 0.3);
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}
.header-nav .header-row {
  display: flex;
  flex-flow: column nowrap;
}
.header-nav .header-row .header-row-inner-wrap {
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
  padding: 10px 20px;
}
.header-nav .header-row .header-cell {
  display: flex;
  gap: 20px;
}
.header-nav .header-row .header-link {
  display: flex;
  align-items: center;
  color: #b0cbac;
  border: unset;
  background: unset;
  font-family: "proxima-nova", -apple-system, "Ubuntu", sans-serif;
  font-size: var(--fluid-body-sm);
  font-weight: 700;
}
.header-nav .nav-cell .fancy-btn, .header-nav .nav-cell .fluentform form .ff-btn, .fluentform form .header-nav .nav-cell .ff-btn, .header-nav .nav-cell .wp-block-button .wp-block-button__link, .wp-block-button .header-nav .nav-cell .wp-block-button__link {
  display: none;
  padding-left: 1em;
  padding-right: 1em;
  font-size: var(--fluid-body-sm);
}
@media (min-width: 1024px) {
  .header-nav .nav-cell .fancy-btn, .header-nav .nav-cell .fluentform form .ff-btn, .fluentform form .header-nav .nav-cell .ff-btn, .header-nav .nav-cell .wp-block-button .wp-block-button__link, .wp-block-button .header-nav .nav-cell .wp-block-button__link {
    display: block;
  }
}
.header-nav .logo-container {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: center;
  text-decoration: none;
  align-self: center;
}
.header-nav .logo-container .logo {
  height: var(--fluid-h2);
}
.header-nav .logo-container .logo-name {
  margin-left: 1em;
  font-size: var(--fluid-h6);
  display: none;
}
@media (min-width: 768px) {
  .header-nav .logo-container .logo-name {
    display: flex;
  }
}
.header-nav .top-row {
  background: #dae6d8;
}
.header-nav .top-row .header-row-inner-wrap {
  padding: 0 0 0 10px;
  justify-content: flex-end;
}
.header-nav .top-row .header-row-inner-wrap .header-link {
  padding: 10px 0;
  margin-left: 0.4em;
  transition: color 0.3s ease;
  display: flex;
  flex-flow: row nowrap;
  text-decoration: none;
  color: white;
  font-size: var(--fluid-body-sm);
}
@media (min-width: 480px) {
  .header-nav .top-row .header-row-inner-wrap .header-link {
    flex-flow: row nowrap;
    margin-left: 1em;
  }
}
@media (min-width: 768px) {
  .header-nav .top-row .header-row-inner-wrap .header-link {
    margin-left: 2em;
  }
}
.header-nav .top-row .header-row-inner-wrap .header-link:first-child {
  margin-left: 0;
}
.header-nav .top-row .header-row-inner-wrap .header-link:hover, .header-nav .top-row .header-row-inner-wrap .header-link:active, .header-nav .top-row .header-row-inner-wrap .header-link:focus {
  color: #b0cbac;
}
.header-nav .top-row .header-row-inner-wrap .header-bold-link {
  background-color: #b0cbac;
  padding-left: 2em;
  padding-right: 2em;
  transition: background-color 0.3s ease;
}
.header-nav .top-row .header-row-inner-wrap .header-bold-link:hover, .header-nav .top-row .header-row-inner-wrap .header-bold-link:active, .header-nav .top-row .header-row-inner-wrap .header-bold-link:focus {
  color: white;
  background-color: #8fb589;
}
.header-nav .top-row .header-row-inner-wrap .header-bold-link:active {
  background-color: #b0cbac;
}
.header-nav .top-row .header-row-inner-wrap button.header-link {
  cursor: pointer;
}
.header-nav .top-row .header-row-inner-wrap .callout-link {
  background-color: #58b947;
  padding: 10px 14px 1em;
  margin-bottom: -8px;
  color: white;
  transition: background 0.3s ease;
}
.header-nav .top-row .header-row-inner-wrap .callout-link .icon {
  color: #b0cbac;
}
.header-nav .top-row .header-row-inner-wrap .callout-link:hover, .header-nav .top-row .header-row-inner-wrap .callout-link:active, .header-nav .top-row .header-row-inner-wrap .callout-link:focus {
  background-color: #71c463;
  color: white !important;
}
.header-nav .top-row .header-row-inner-wrap button {
  padding: 0;
}

#desktop-nav {
  display: none;
}
@media (min-width: 1024px) {
  #desktop-nav {
    display: flex;
  }
}
#desktop-nav div:first-child {
  display: flex;
}
#desktop-nav .menu {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}
#desktop-nav a {
  text-transform: uppercase;
  font-weight: 700;
  letter-spacing: 1px;
  margin-left: 0.2rem;
  margin-right: 0.2rem;
}

#mobile-nav-button {
  display: flex;
}
@media (min-width: 1024px) {
  #mobile-nav-button {
    display: none;
  }
}

#navigation-modal [data-bm-card] {
  display: flex;
  flex-flow: column nowrap;
  width: 100%;
  background: #333333;
  height: 100%;
}
@media (min-width: 768px) {
  #navigation-modal [data-bm-card] {
    width: auto;
    min-width: 40%;
  }
}
#navigation-modal [data-bm-card] .bm-card-inner {
  padding: 2em;
  display: flex;
  flex-flow: column nowrap;
  flex-grow: 1;
}
#navigation-modal [data-bm-card] [data-bm-button] {
  margin-left: auto;
  padding: 1rem;
  font-weight: 500;
  cursor: pointer;
}
#navigation-modal [data-bm-card] [data-bm-button] svg {
  padding: 0.4rem 0.2rem;
  color: white;
}
#navigation-modal [data-bm-card] a {
  color: white;
  align-self: center;
  text-align: center;
  font-size: var(--fluid-h6);
  margin-bottom: 1em;
}
#navigation-modal [data-bm-card] #mobile-nav {
  margin: auto;
}
#navigation-modal [data-bm-card] .mobile-callout-btn {
  width: 100%;
  display: flex;
  margin-top: auto;
  text-align: center;
  justify-content: center;
}

#footer {
  background: #d1d1d1;
  position: relative;
  z-index: 2;
}
#footer .s-inner-wrap {
  padding-top: 25px;
  padding-bottom: 25px;
  width: 320px;
}
@media (min-width: 768px) {
  #footer .s-inner-wrap {
    width: 600px;
  }
}
@media (min-width: 1024px) {
  #footer .s-inner-wrap {
    width: 1400px;
  }
}
#footer .f-grid {
  --cell-size-sm: 100%;
  --cell-size-md: 50%;
  --cell-size-lg: 25%;
  justify-content: space-between;
  flex-flow: column nowrap;
  --grid-gap: 0;
}
@media (min-width: 768px) {
  #footer .f-grid {
    flex-flow: row wrap;
  }
}
@media (min-width: 1024px) {
  #footer .f-grid {
    flex-flow: row nowrap;
  }
}
@media (min-width: 768px) {
  #footer .f-grid {
    --grid-gap: 15px;
  }
}
#footer .f-grid .cell {
  flex-grow: 1;
  flex-shrink: 0;
}
#footer ul {
  padding-left: 0;
  list-style: none;
}
#footer ul a {
  color: #333333;
  text-decoration: none;
  text-transform: uppercase;
  letter-spacing: 1px;
  font-weight: 700;
}
#footer ul a:hover, #footer ul a:active, #footer ul a:focus {
  color: #58b947;
}
#footer ul a:active {
  color: #8fb589;
}
#footer .appointment-cell {
  padding-top: 1rem;
  width: 100%;
}
@media (min-width: 768px) {
  #footer .appointment-cell {
    width: auto;
  }
}
#footer .appointment-cell .fancy-btn, #footer .appointment-cell .fluentform form .ff-btn, .fluentform form #footer .appointment-cell .ff-btn, #footer .appointment-cell .wp-block-button .wp-block-button__link, .wp-block-button #footer .appointment-cell .wp-block-button__link {
  width: 100%;
  align-self: stretch;
  align-items: center;
  justify-content: center;
  text-align: center;
  font-size: var(--fluid-body-sm);
  padding: 0.8em 1.4em;
}
@media (min-width: 768px) {
  #footer .appointment-cell .fancy-btn, #footer .appointment-cell .fluentform form .ff-btn, .fluentform form #footer .appointment-cell .ff-btn, #footer .appointment-cell .wp-block-button .wp-block-button__link, .wp-block-button #footer .appointment-cell .wp-block-button__link {
    width: auto;
  }
}
#footer .media-header {
  margin-top: 1rem;
  font-weight: 600;
}
#footer .links-cell {
  display: flex;
  flex-flow: row wrap;
  column-gap: 20px;
}
#footer .media-wrap {
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  margin: 0 calc(-1 * var(--fluid-body));
  margin-top: 1rem;
  margin-bottom: 0.5rem;
}
#footer .media-wrap .media-link .media-icon {
  display: block;
  padding: 0.5rem var(--fluid-body);
}
#footer .media-wrap .media-link .media-icon svg {
  height: var(--fluid-h5);
}
#footer .logo-footer {
  margin-top: 0.75rem;
  padding-right: 0.5rem;
}
#footer .logo-footer img {
  width: 250px;
  max-width: 100%;
}
#footer .mobile-appointment {
  display: flex;
}
@media (min-width: 768px) {
  #footer .mobile-appointment {
    display: none;
  }
}
#footer .hide-mobile {
  display: none;
}
@media (min-width: 768px) {
  #footer .hide-mobile {
    display: flex;
  }
}
#footer .contact-info a {
  color: #58b947;
  text-decoration: none;
}
#footer .contact-info a:hover, #footer .contact-info a:active, #footer .contact-info a:focus {
  color: #4b9e3c;
}
#footer .contact-info a:active {
  color: #428b35;
}
#footer .subfooter-wrapper {
  background-color: #707070;
  width: 100%;
}
#footer .subfooter-wrapper .subfooter-content {
  display: flex;
  align-items: center;
  justify-content: center;
}
#footer .subfooter-wrapper .subfooter-content a {
  color: #333333;
  text-decoration: none;
  text-transform: uppercase;
  letter-spacing: 1px;
  font-weight: 700;
}
#footer .subfooter-wrapper .subfooter-content a:hover, #footer .subfooter-wrapper .subfooter-content a:active, #footer .subfooter-wrapper .subfooter-content a:focus {
  color: #58b947;
}
#footer .subfooter-wrapper .subfooter-content a:active {
  color: #8fb589;
}

.c-grid {
  --grid-col-sm: 1fr;
  --grid-col-md: 1fr;
  --grid-col-lg: repeat(2, 1fr);
  --grid-gap: 10px;
  display: grid;
  grid-template-columns: var(--grid-col-sm);
  grid-gap: var(--grid-gap);
}
@media (min-width: 768px) {
  .c-grid {
    grid-template-columns: var(--grid-col-md);
  }
}
@media (min-width: 1024px) {
  .c-grid {
    grid-template-columns: var(--grid-col-lg);
  }
}
.c-grid .cell {
  display: flex;
  flex-flow: column nowrap;
  height: auto;
}

.f-grid {
  display: flex;
  flex-flow: row wrap;
  --cell-size-sm: 100%;
  --cell-size-md: 50%;
  --cell-size-lg: 33.33%;
  --grid-gap: 30px;
  margin: calc(var(--grid-gap) * 0.5 * -1);
}
.f-grid .cell {
  display: flex;
  flex-flow: column nowrap;
  height: auto;
  flex-basis: calc(var(--cell-size-sm) - var(--grid-gap));
  margin: calc(var(--grid-gap) * 0.5);
}
@media (min-width: 768px) {
  .f-grid .cell {
    flex-basis: calc(var(--cell-size-md) - var(--grid-gap));
  }
}
@media (min-width: 1024px) {
  .f-grid .cell {
    flex-basis: calc(var(--cell-size-lg) - var(--grid-gap));
  }
}

.block-quote {
  padding: var(--fluid-h4);
  font-weight: 700;
  font-size: var(--fluid-h5);
  line-height: 1.2;
  max-width: 225px;
  background: #58b947;
  color: white;
}
.block-quote.quote-black {
  background: #333333;
}
.block-quote.quote-sm {
  font-size: var(--fluid-h6);
  padding: var(--fluid-h6);
}

.cube-title {
  display: flex;
  flex-flow: row nowrap;
  align-content: center;
  justify-content: center;
  align-self: flex-start;
}
.cube-title:before {
  content: "";
  display: flex;
  height: 1em;
  width: 1em;
  margin-right: 14px;
  background-color: #b0cbac;
  flex-shrink: 0;
  margin-top: auto;
  margin-bottom: auto;
}
.cube-title.title-green:before {
  background-color: #58b947;
}

.body-controller {
  display: flex;
  flex-flow: column nowrap;
  min-height: 100vh;
}
.body-controller footer {
  margin-top: auto;
}

body {
  margin: 0;
}
body:not([data-s-loaded]) * {
  transition: none !important;
}

.uagb-column__inner-wrap {
  display: flex;
  flex-flow: column nowrap;
}

.fluentform form .ff-btn {
  border: unset !important;
  background: #58b947 !important;
  font-size: var(--fluid-body);
}
.fluentform form .ff-btn:hover, .fluentform form .ff-btn:active, .fluentform form .ff-btn:focus {
  opacity: 1;
  background: #4b9e3c !important;
}
.fluentform form .ff-btn:active {
  background: #428b35 !important;
}
.fluentform form .ff-el-form-control {
  border-radius: 0;
}
.fluentform textarea {
  font-family: "proxima-nova", -apple-system, "Ubuntu", sans-serif;
}

/**
 * Functional better menu styles
 * Better off not touching this stuff
 **/
[data-better-menu] {
  --bm-a-pad-top: 0.4rem;
  --bm-a-pad-left: 0.5rem;
  --bm-a-pad-right: 0.5rem;
  --bm-a-pad-bottom: 0.4rem;
  --bm-a-color: #707070;
  --bm-a-weight: bold;
  --bm-a-size: var(--fluid-body-sm);
  --bm-a-color-hover: #58b947;
  --bm-a-color-active: #4b9e3c;
  --bm-a-transition: all 0.3s ease;
  --bm-submenu-width: 225px;
  --bm-submenu-text-alignment: left;
  --bm-submenu-item-alignment: flex-start;
  --bm-submenu-hor-pad: 0.75rem 0.5rem;
  --bm-vert-submenu-pad: 1rem 0.5rem;
  --bm-dropdown-arrow-height: 15px;
  --bm-dropdown-arrow-width: 18px;
  --bm-dropdown-arrow-padding: 8px;
  --bm-dropdown-arrow-color: #58b947;
}

[data-better-modal] {
  --bm-transition-duration: 0.5s;
  --bm-transition-function: ease;
  --bm-overlay-color: rgba($black, 0.1);
}

[data-bm-button] {
  --bm-button-padding-tb: 10px;
  --bm-button-padding-lr: 10px;
  --bm-button-background-color: transparent;
  --bm-button-border: unset;
  --bm-button-border-radius: 0;
  --bm-toggle-line-width: 40px;
  --bm-toggle-line-height: 4px;
  --bm-toggle-line-gap: 8px;
  --bm-toggle-line-border-radius: 10px;
  --bm-toggle-line-color: #333333;
}

[data-better-menu] {
  display: flex;
}
[data-better-menu] ul,
[data-better-menu] li,
[data-better-menu] a,
[data-better-menu] div {
  margin: unset;
  padding: unset;
}
[data-better-menu] ul {
  display: flex;
  list-style: none;
}
[data-better-menu] ul li {
  position: relative;
  display: flex;
  flex-flow: column nowrap;
}
[data-better-menu] ul a {
  width: auto;
  align-self: var(--bm-submenu-item-alignment);
}
[data-better-menu] ul ul {
  visibility: hidden;
  flex-flow: column nowrap;
  height: 0;
}
[data-better-menu] ul [data-bm-dropdown-anchor] {
  position: relative;
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
}
[data-better-menu] ul [data-bm-dropdown-anchor] [data-bm-dropdown-arrow] {
  pointer-events: all;
  padding: var(--bm-dropdown-arrow-padding);
  transition: var(--bm-a-transition);
  transform: scaleY(1);
}
[data-better-menu] ul [data-bm-dropdown-anchor] [data-bm-dropdown-arrow] svg {
  height: var(--bm-dropdown-arrow-height);
  width: var(--bm-dropdown-arrow-width);
  color: var(--bm-dropdown-arrow-color);
  pointer-events: none;
}
[data-better-menu] ul [data-bm-dropdown-anchor][data-bm-active] [data-bm-dropdown-arrow] {
  transform: scaleY(-1);
}

[data-better-menu=vertical] ul {
  flex-flow: column nowrap;
}
[data-better-menu=vertical] ul li ul {
  height: 0;
}
[data-better-menu=vertical] ul li ul[data-bm-active] {
  visibility: visible;
}

[data-better-menu=horizontal] ul {
  flex-flow: row nowrap;
  align-items: center;
}
[data-better-menu=horizontal] ul li ul {
  position: absolute;
  top: 100%;
  right: 0;
  align-items: var(--bm-submenu-item-alignment);
}
[data-better-menu=horizontal] ul li ul[data-bm-active] {
  visibility: visible;
}

/**
 * Better Menu Theme file!
 */
/**
 * Better Menu Theme file!
 */
[data-better-menu] a {
  padding: var(--bm-a-pad-top) var(--bm-a-pad-right) var(--bm-a-pad-bottom) var(--bm-a-pad-left);
  color: var(--bm-a-color);
  font-weight: var(--bm-a-weight);
  font-size: var(--bm-a-size);
  transition: var(--bm-a-transition);
  text-decoration: none;
}
[data-better-menu] a:hover, [data-better-menu] a:focus {
  color: var(--bm-a-color-hover);
}
[data-better-menu] a:active, [data-better-menu] a[data-bm-active-anchor] {
  color: var(--bm-a-color-active);
}

[data-better-menu=vertical] ul li ul {
  position: relative;
  height: max-content;
  max-height: 0;
  padding: 0;
  overflow: hidden;
  transition: max-height 0.5s ease, visibility 0.5s ease, padding 0.5s ease, transform 0.5s ease;
}
[data-better-menu=vertical] ul li ul[data-bm-active] {
  padding: var(--bm-vert-submenu-pad);
  max-height: 300px;
  visibility: visible;
}

[data-better-menu=horizontal] ul li:hover ul {
  z-index: 105;
}
[data-better-menu=horizontal] ul li ul {
  position: absolute;
  top: 100%;
  height: auto;
  width: var(--bm-submenu-width);
  padding: var(--bm-submenu-hor-pad);
  transition: transform 0.3s ease, visibility 0.3s ease;
  transform-origin: top;
  border: 2px solid #58b947;
  transform: scaleY(0);
  background: #fff;
  z-index: 100;
}
[data-better-menu=horizontal] ul li ul:hover {
  z-index: 105;
}
[data-better-menu=horizontal] ul li ul[data-bm-active] {
  transform: scaleY(1);
  visibility: visible;
}

/**
 * Better Menu Theme file!
 */
/**
 * Better Menu Theme file!
 */
[data-better-menu] a {
  padding: var(--bm-a-pad-top) var(--bm-a-pad-right) var(--bm-a-pad-bottom) var(--bm-a-pad-left);
  color: var(--bm-a-color);
  font-weight: var(--bm-a-weight);
  font-size: var(--bm-a-size);
  transition: var(--bm-a-transition);
  text-decoration: none;
}
[data-better-menu] a:hover, [data-better-menu] a:focus {
  color: var(--bm-a-color-hover);
}
[data-better-menu] a:active, [data-better-menu] a[data-bm-active-anchor] {
  color: var(--bm-a-color-active);
}

[data-better-menu=vertical] ul li ul {
  position: relative;
  height: max-content;
  max-height: 0;
  padding: 0;
  overflow: hidden;
  transition: max-height 0.5s ease, visibility 0.5s ease, padding 0.5s ease, transform 0.5s ease;
}
[data-better-menu=vertical] ul li ul[data-bm-active] {
  padding: var(--bm-vert-submenu-pad);
  max-height: 300px;
  visibility: visible;
}

[data-better-menu=horizontal] ul li:hover ul {
  z-index: 105;
}
[data-better-menu=horizontal] ul li ul {
  position: absolute;
  top: 100%;
  height: auto;
  width: var(--bm-submenu-width);
  padding: var(--bm-submenu-hor-pad);
  transition: transform 0.3s ease, visibility 0.3s ease;
  transform-origin: top;
  border: 2px solid #58b947;
  transform: scaleY(0);
  background: #fff;
  z-index: 100;
}
[data-better-menu=horizontal] ul li ul:hover {
  z-index: 105;
}
[data-better-menu=horizontal] ul li ul[data-bm-active] {
  transform: scaleY(1);
  visibility: visible;
}

[data-bm-button] {
  position: relative;
  cursor: pointer;
  background: unset;
  border: unset;
}

[data-better-modal] {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  visibility: hidden;
  z-index: 100;
}
[data-better-modal][data-bm-is-open] {
  visibility: visible;
}
[data-better-modal] [data-bm-overlay] {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  z-index: -1;
}
[data-better-modal] [data-bm-card] {
  max-width: 100vw;
  max-height: 100vh;
  overflow: auto;
}
[data-better-modal][data-bm-position=top] {
  justify-content: center;
  align-items: flex-start;
}
[data-better-modal][data-bm-position=left] {
  justify-content: flex-start;
  align-items: center;
}
[data-better-modal][data-bm-position=right] {
  justify-content: flex-end;
  align-items: center;
}
[data-better-modal][data-bm-position=bottom] {
  justify-content: center;
  align-items: flex-end;
}
[data-better-modal][data-bm-position=center] {
  justify-content: center;
  align-items: center;
}

body[data-bm-offcanvas] {
  position: relative;
}

body {
  margin: 0;
}
body:not([data-s-loaded]) * {
  transition: none !important;
}

[data-bm-button] {
  display: flex;
  flex-flow: column nowrap;
  text-align: center;
  justify-content: center;
  align-items: center;
  position: relative;
  padding: var(--bm-button-padding-tb) var(--bm-button-padding-lr);
  background: var(--bm-button-background-color);
  border: var(--bm-button-border);
}
[data-bm-button][data-bm-close] .close-icon {
  width: var(--bm-toggle-line-width);
}

.toggle-line {
  display: flex;
  width: var(--bm-toggle-line-width);
  height: var(--bm-toggle-line-height);
  margin-bottom: var(--bm-toggle-line-gap);
  border-radius: var(--bm-toggle-line-border-radius);
  background: var(--bm-toggle-line-color);
}

[data-bm-animation] {
  transition-duration: var(--bm-transition-duration);
  transition-timing-function: var(--bm-transition-duration);
}

[data-bm-animation~=slide-top] {
  transform: translateY(-100%);
}
[data-bm-animation~=slide-top][data-bm-active] {
  transform: translateY(0%);
}

[data-bm-animation~=slide-left] {
  transform: translate(-100%);
}
[data-bm-animation~=slide-left][data-bm-active] {
  transform: translate(0%);
}

[data-bm-animation~=slide-right] {
  transform: translate(100%);
}
[data-bm-animation~=slide-right][data-bm-active] {
  transform: translate(0%);
}

[data-bm-animation~=slide-bottom] {
  transform: translateY(100%);
}
[data-bm-animation~=slide-bottom][data-bm-active] {
  transform: translateY(0%);
}

[data-bm-animation~=fade] {
  opacity: 0;
}
[data-bm-animation~=fade][data-bm-active] {
  opacity: 1;
}

[data-better-modal] [data-bm-overlay] {
  transition: background var(--bm-transition-duration) var(--bm-transition-timing-function);
  background: transparent;
}
[data-better-modal] [data-bm-overlay][data-bm-active] {
  background: var(--bm-overlay-color);
}
[data-better-modal] [data-better-modal][data-bm-animation=bottom] {
  transform-origin: top center;
  bottom: 0;
  left: 50%;
  transform: translate(-50%, 100%);
}
[data-better-modal] [data-better-modal][data-bm-animation=bottom][data-bm-is-open] {
  transform: translate(-50%, 0%);
}
[data-better-modal][data-bm-animation=center] {
  transform-origin: center center;
  width: 80%;
  height: 80%;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  opacity: 0;
}
[data-better-modal][data-bm-animation=center][data-bm-is-open] {
  transform: translate(-50%, -50%);
  opacity: 1;
}
[data-bm-button] {
  display: flex;
  flex-flow: column nowrap;
  text-align: center;
  justify-content: center;
  align-items: center;
  position: relative;
  padding: var(--bm-button-padding-tb) var(--bm-button-padding-lr);
  background: var(--bm-button-background-color);
  border: var(--bm-button-border);
}
[data-bm-button][data-bm-close] .close-icon {
  width: var(--bm-toggle-line-width);
}

.toggle-line {
  display: flex;
  width: var(--bm-toggle-line-width);
  height: var(--bm-toggle-line-height);
  margin-bottom: var(--bm-toggle-line-gap);
  border-radius: var(--bm-toggle-line-border-radius);
  background: var(--bm-toggle-line-color);
}

[data-bm-animation] {
  transition-duration: var(--bm-transition-duration);
  transition-timing-function: var(--bm-transition-duration);
}

[data-bm-animation~=slide-top] {
  transform: translateY(-100%);
}
[data-bm-animation~=slide-top][data-bm-active] {
  transform: translateY(0%);
}

[data-bm-animation~=slide-left] {
  transform: translate(-100%);
}
[data-bm-animation~=slide-left][data-bm-active] {
  transform: translate(0%);
}

[data-bm-animation~=slide-right] {
  transform: translate(100%);
}
[data-bm-animation~=slide-right][data-bm-active] {
  transform: translate(0%);
}

[data-bm-animation~=slide-bottom] {
  transform: translateY(100%);
}
[data-bm-animation~=slide-bottom][data-bm-active] {
  transform: translateY(0%);
}

[data-bm-animation~=fade] {
  opacity: 0;
}
[data-bm-animation~=fade][data-bm-active] {
  opacity: 1;
}

[data-better-modal] [data-bm-overlay] {
  transition: background var(--bm-transition-duration) var(--bm-transition-timing-function);
  background: transparent;
}
[data-better-modal] [data-bm-overlay][data-bm-active] {
  background: var(--bm-overlay-color);
}
[data-better-modal] [data-better-modal][data-bm-animation=bottom] {
  transform-origin: top center;
  bottom: 0;
  left: 50%;
  transform: translate(-50%, 100%);
}
[data-better-modal] [data-better-modal][data-bm-animation=bottom][data-bm-is-open] {
  transform: translate(-50%, 0%);
}
[data-better-modal][data-bm-animation=center] {
  transform-origin: center center;
  width: 80%;
  height: 80%;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  opacity: 0;
}
[data-better-modal][data-bm-animation=center][data-bm-is-open] {
  transform: translate(-50%, -50%);
  opacity: 1;
}
:root {
  --fluid-jumbo: 55px;
}
@media (min-width: 400px) {
  :root {
    --fluid-jumbo: calc(
					55px + 20 *
						((100vw - 400px) / 800)
				);
  }
}
@media (min-width: 1200px) {
  :root {
    --fluid-jumbo: 75px;
  }
}
:root {
  --fluid-h1: 40px;
}
@media (min-width: 400px) {
  :root {
    --fluid-h1: calc(
					40px + 12 *
						((100vw - 400px) / 800)
				);
  }
}
@media (min-width: 1200px) {
  :root {
    --fluid-h1: 52px;
  }
}
:root {
  --fluid-h2: 30px;
}
@media (min-width: 400px) {
  :root {
    --fluid-h2: calc(
					30px + 10 *
						((100vw - 400px) / 800)
				);
  }
}
@media (min-width: 1200px) {
  :root {
    --fluid-h2: 40px;
  }
}
:root {
  --fluid-h3: 28px;
}
@media (min-width: 400px) {
  :root {
    --fluid-h3: calc(
					28px + 8 *
						((100vw - 400px) / 800)
				);
  }
}
@media (min-width: 1200px) {
  :root {
    --fluid-h3: 36px;
  }
}
:root {
  --fluid-h4: 24px;
}
@media (min-width: 400px) {
  :root {
    --fluid-h4: calc(
					24px + 8 *
						((100vw - 400px) / 800)
				);
  }
}
@media (min-width: 1200px) {
  :root {
    --fluid-h4: 32px;
  }
}
:root {
  --fluid-h5: 22px;
}
@media (min-width: 400px) {
  :root {
    --fluid-h5: calc(
					22px + 6 *
						((100vw - 400px) / 800)
				);
  }
}
@media (min-width: 1200px) {
  :root {
    --fluid-h5: 28px;
  }
}
:root {
  --fluid-h6: 20px;
}
@media (min-width: 400px) {
  :root {
    --fluid-h6: calc(
					20px + 4 *
						((100vw - 400px) / 800)
				);
  }
}
@media (min-width: 1200px) {
  :root {
    --fluid-h6: 24px;
  }
}
:root {
  --fluid-body: 16px;
}
@media (min-width: 400px) {
  :root {
    --fluid-body: calc(
					16px + 2 *
						((100vw - 400px) / 800)
				);
  }
}
@media (min-width: 1200px) {
  :root {
    --fluid-body: 18px;
  }
}
:root {
  --fluid-body-sm: 14px;
}
@media (min-width: 400px) {
  :root {
    --fluid-body-sm: calc(
					14px + 2 *
						((100vw - 400px) / 800)
				);
  }
}
@media (min-width: 1200px) {
  :root {
    --fluid-body-sm: 16px;
  }
}
:root {
  --fluid-body-xs: 12px;
}
@media (min-width: 400px) {
  :root {
    --fluid-body-xs: calc(
					12px + 2 *
						((100vw - 400px) / 800)
				);
  }
}
@media (min-width: 1200px) {
  :root {
    --fluid-body-xs: 14px;
  }
}

h1,
.fluid-h1 {
  font-size: var(--fluid-h1);
}

h2,
.fluid-h2 {
  font-size: var(--fluid-h2);
}

h3,
.fluid-h3 {
  font-size: var(--fluid-h3);
}

h4,
.fluid-h4 {
  font-size: var(--fluid-h4);
}

h5,
.fluid-h5 {
  font-size: var(--fluid-h5);
}

h6,
.fluid-h6 {
  font-size: var(--fluid-h6);
}

html,
.fluid-body {
  font-size: var(--fluid-body);
}

.fluid-body-sm {
  font-size: var(--fluid-body-sm);
}

#hero {
  position: relative;
}
#hero:after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 0;
}
@supports (backdrop-filter: brightness(0.5)) {
  #hero:after {
    backdrop-filter: brightness(0.5);
  }
}
@supports not (backdrop-filter: brightness(0.5)) {
  #hero:after {
    background: rgba(51, 51, 51, 0.6);
  }
}
#hero .s-inner-wrap {
  position: relative;
  z-index: 1;
  width: 600px;
  padding-top: 120px;
  padding-bottom: 30px;
}
#hero h1 {
  text-align: center;
  color: white;
}
#hero .words-wrap {
  display: flex;
  align-items: center;
  color: white;
  font-weight: bold;
  font-size: var(--fluid-h6);
  text-transform: uppercase;
}
#hero .words-wrap {
  font-size: 12px;
}
@media (min-width: 400px) {
  #hero .words-wrap {
    font-size: calc(
					12px + 8 *
						((100vw - 400px) / 800)
				);
  }
}
@media (min-width: 1200px) {
  #hero .words-wrap {
    font-size: 20px;
  }
}
#hero .words-wrap {
  --circle-size: 4px;
}
@media (min-width: 400px) {
  #hero .words-wrap {
    --circle-size: calc(
					4px + 4 *
						((100vw - 400px) / 800)
				);
  }
}
@media (min-width: 1200px) {
  #hero .words-wrap {
    --circle-size: 8px;
  }
}
#hero .words-wrap .circle {
  display: block;
  margin: var(--circle-size);
  width: var(--circle-size);
  height: var(--circle-size);
  border-radius: 500px;
  background: white;
}
#hero .hero-footer-image {
  margin-top: 75px;
}
#hero .fancy-btn, #hero .wp-block-button .wp-block-button__link, .wp-block-button #hero .wp-block-button__link, #hero .fluentform form .ff-btn, .fluentform form #hero .ff-btn {
  margin: 30px 0;
  align-self: center;
}

#together .f-grid {
  --cell-size-lg: auto;
  --cell-size-md: auto;
  --grid-gap: 40px;
  align-items: center;
  justify-content: center;
  max-width: 100%;
}
@media (min-width: 768px) {
  #together .f-grid {
    flex-flow: row nowrap;
  }
}
#together .f-grid .cell {
  max-width: 100%;
}
#together .f-grid .quote-cell {
  flex-shrink: 0;
  align-items: flex-start;
}
@media (min-width: 768px) {
  #together .f-grid .quote-cell {
    align-items: flex-start;
  }
}
#together .f-grid .info-wrap {
  width: 100%;
  max-width: 600px;
  margin: 0 auto;
  font-size: var(--fluid-body-lg);
  font-weight: 500;
}
#together .f-grid .info-wrap p:first-child {
  margin-top: 0;
}
@media (min-width: 768px) {
  #together .f-grid .info-wrap {
    margin: unset;
  }
}

#program-benefits {
  background-color: #dae6d8;
}
#program-benefits h2 {
  text-align: center;
  align-self: center;
}
#program-benefits .c-grid {
  --grid-col-lg: repeat(4, 1fr);
  --grid-col-md: repeat(2, 1fr);
  --grid-col-sm: repeat(2, 1fr);
  --grid-gap: 10px;
  margin: 20px auto;
}
@media (min-width: 768px) {
  #program-benefits .c-grid {
    --grid-gap: 20px;
    width: 500px;
  }
}
@media (min-width: 1024px) {
  #program-benefits .c-grid {
    width: auto;
  }
}
#program-benefits .block-quote {
  display: flex;
  align-items: center;
  width: 100%;
  height: 100%;
  max-width: unset;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  aspect-ratio: 1/0.7;
}
#program-benefits .block-quote {
  padding: 12px;
}
@media (min-width: 400px) {
  #program-benefits .block-quote {
    padding: calc(
					12px + 13 *
						((100vw - 400px) / 800)
				);
  }
}
@media (min-width: 1200px) {
  #program-benefits .block-quote {
    padding: 25px;
  }
}
#program-benefits .block-quote {
  font-size: 18px;
}
@media (min-width: 400px) {
  #program-benefits .block-quote {
    font-size: calc(
					18px + 6 *
						((100vw - 400px) / 800)
				);
  }
}
@media (min-width: 1200px) {
  #program-benefits .block-quote {
    font-size: 24px;
  }
}
#program-benefits a.block-quote {
  text-decoration: none;
}
#program-benefits a.block-quote:hover, #program-benefits a.block-quote:active, #program-benefits a.block-quote:focus {
  transform: scale(1.05);
}
#program-benefits .fancy-btn, #program-benefits .wp-block-button .wp-block-button__link, .wp-block-button #program-benefits .wp-block-button__link, #program-benefits .fluentform form .ff-btn, .fluentform form #program-benefits .ff-btn {
  align-self: center;
  margin-top: 20px;
}

#more-info .c-grid {
  --grid-gap: 40px;
}
#more-info .info-cell .fancy-btn, #more-info .info-cell .wp-block-button .wp-block-button__link, .wp-block-button #more-info .info-cell .wp-block-button__link, #more-info .info-cell .fluentform form .ff-btn, .fluentform form #more-info .info-cell .ff-btn {
  margin-top: 1rem;
  align-self: flex-start;
}
#more-info .video-cell .video-button {
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  justify-content: center;
  background-image: url("../images/conference-room.jpg");
  background-position: bottom center;
  background-size: cover;
  box-shadow: inset 0 0 0 2000px rgba(51, 51, 51, 0.4);
  border: unset;
  padding: 30px;
  color: white;
  font-weight: 600;
  font-size: var(--fluid-body-lg);
  transition: all 0.3s ease;
}
#more-info .video-cell .video-button .video-triangle {
  padding-bottom: 45%;
  width: 45%;
  height: auto;
  background-color: rgba(255, 255, 255, 0.6);
  clip-path: polygon(20% 15%, 20% 85%, 80% 50%);
  z-index: 1;
  transition: all 0.3s ease;
}
#more-info .video-cell .video-button:hover {
  transform: scale(1.02);
  background-color: rgba(255, 255, 255, 0.4);
}
#more-info .video-cell .video-button:active .video-triangle, #more-info .video-cell .video-button:focus .video-triangle {
  background-color: rgba(255, 255, 255, 0.9);
}

.video-modal {
  --bm-overlay-color: rgba(0, 0, 0, 0.3);
}
.video-modal [data-bm-card] {
  width: 1200px;
  max-width: 100%;
  margin: 0 auto;
  padding: 1rem;
}
@media (min-width: 768px) {
  .video-modal [data-bm-card] {
    max-width: 85%;
  }
}
@media (min-width: 1024px) {
  .video-modal [data-bm-card] {
    max-width: 1200px;
  }
}
.video-modal [data-bm-close] {
  color: white;
  font-size: 4rem;
  margin-left: auto;
  filter: drop-shadow(0 0 4px black);
}
.video-modal .video-wrap {
  position: relative;
  width: 100%;
  flex-grow: 1;
  padding-bottom: 56.25%;
  margin: 1rem;
  margin: 0 auto;
}
.video-modal .video-wrap video, .video-modal .video-wrap iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

#home-news {
  background-color: #dae6d8;
  --swiper-theme-color: #333333;
  --swiper-navigation-size: 35px;
}
#home-news #home-slider {
  position: relative;
  margin-top: 40px;
  padding-left: 50px;
  padding-right: 50px;
}
#home-news .swiper .swiper-slide {
  flex-grow: 1;
  height: auto;
  padding-bottom: 15px;
}

.news-card {
  display: flex;
  flex-flow: column nowrap;
  height: 100%;
  text-decoration: none;
  border-radius: 5px;
  box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.2);
  padding: 10px;
  margin: 10px;
  background-color: rgba(255, 255, 255, 0.7);
}
.news-card .image-wrap {
  position: relative;
  padding-bottom: 80%;
  margin-bottom: 0.5rem;
  transition: filter 0.3s ease;
  border-radius: 4px;
  overflow: hidden;
}
.news-card .image-wrap img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center center;
}
.news-card .info-wrap {
  display: flex;
  flex-flow: column nowrap;
  flex-grow: 1;
  text-decoration: none;
  color: #333333;
}
.news-card .info-wrap .news-date {
  color: #58b947;
  font-weight: bold;
  margin: 0.3em 0;
  text-decoration: none !important;
}
.news-card .info-wrap .news-link {
  margin-top: auto;
  align-self: flex-end;
  text-decoration: none;
}
.news-card .info-wrap .news-title {
  font-weight: 700;
  line-height: 1.4;
  margin-bottom: 1rem;
}
.news-card:hover .image-wrap, .news-card:focus .image-wrap {
  filter: brightness(0.9);
}
.news-card:hover .fancy-link, .news-card:focus .fancy-link {
  color: #4b9e3c;
}
.news-card:active .fancy-link {
  color: #428b35;
}
.news-card .fancy-link {
  color: #58b947;
  transform: all 0.3s ease;
}

#office-space .s-inner-wrap {
  padding-bottom: calc(var(--fluid-section-padding) / 1.5);
}
#office-space .c-grid {
  margin-top: 20px;
  --grid-gap: 20px;
  --grid-col-md: 1fr 1fr;
  width: 400px;
  max-width: 100%;
  margin-left: auto;
  margin-right: auto;
}
@media (min-width: 768px) {
  #office-space .c-grid {
    width: 100%;
  }
}
#office-space .office-card {
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  justify-content: center;
  padding: 80px 40px;
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;
  box-shadow: inset 0 0 0 2000px rgba(51, 51, 51, 0.6);
  height: 100%;
  aspect-ratio: 100/40;
}
#office-space .office-card span {
  text-align: center;
  font-size: var(--fluid-h4);
  color: white;
  font-weight: bold;
  max-width: 100%;
  margin-bottom: 50px;
  line-height: 1.2;
}
#office-space .office-card .fancy-btn, #office-space .office-card .wp-block-button .wp-block-button__link, .wp-block-button #office-space .office-card .wp-block-button__link, #office-space .office-card .fluentform form .ff-btn, .fluentform form #office-space .office-card .ff-btn {
  align-self: center;
}

#partners #partner-slider {
  margin-top: 50px;
  margin-bottom: 50px;
}
#partners .swiper-wrapper {
  display: flex;
  transition-timing-function: linear;
}
#partners .swiper-slide {
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  align-content: center;
  justify-content: center;
  justify-items: center;
  height: 100%;
  width: auto;
}
#partners img {
  max-height: 115px;
  transition: all 0.3s ease;
  transform: scale(0.9);
}
#partners img:hover, #partners img:focus {
  transform: scale(1);
}
#partners img:active {
  transform: scale(1.02);
}

.page-header {
  position: relative;
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;
  --max-width: 100%;
}
.page-header .inner-wrap {
  display: flex;
  padding: 80px 20px;
  padding-bottom: 100px;
  align-self: center;
  max-width: 100%;
  width: 1200px;
}
.page-header .inner-wrap h1 {
  align-self: center;
  font-size: var(--fluid-h2);
  margin: 0 auto;
  max-width: 100%;
  color: white;
}
.page-header .background-image {
  position: absolute;
  top: 0;
  left: 0;
  width: var(--max-width);
  max-width: 100%;
  height: 100%;
  z-index: -1;
  object-fit: cover;
}
.page-header .background-image:after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
@supports (backdrop-filter: brightness(0.5)) {
  .page-header .background-image:after {
    backdrop-filter: brightness(0.5);
  }
}
@supports not (backdrop-filter: brightness(0.5)) {
  .page-header .background-image:after {
    background: rgba(51, 51, 51, 0.6);
  }
}
.page-header .background-image img {
  height: 100%;
  width: 100%;
  object-fit: cover;
  object-position: center center;
}

#not-found-page .fancy-btn, #not-found-page .wp-block-button .wp-block-button__link, .wp-block-button #not-found-page .wp-block-button__link, #not-found-page .fluentform form .ff-btn, .fluentform form #not-found-page .ff-btn {
  text-align: center;
  align-self: stretch;
  justify-content: center;
  margin-bottom: 1em;
  width: auto;
}
#not-found-page .callout {
  margin-top: 0.2em;
  font-size: var(--fluid-h6);
}
#not-found-page .btn-wrap {
  display: flex;
  flex-flow: column nowrap;
  width: 275px;
  max-width: 100%;
}

.news-archive .c-grid {
  --grid-col-lg: 1fr 1fr 1fr;
  --grid-col-md: 1fr 1fr;
  --grid-col-sm: 1fr;
  --grid-gap: 25px;
}
@media (min-width: 768px) {
  .news-archive .c-grid {
    --grid-gap: 30px;
  }
}
@media (min-width: 1024px) {
  .news-archive .c-grid {
    --grid-gap: 40px;
  }
}

.news-single h1 {
  font-size: var(--fluid-h2);
}
.news-single .s-inner-wrap {
  padding-top: 25px;
  width: 60ch;
}
.news-single .post-date {
  font-size: var(--fluid-h6);
  color: #58b947;
  font-weight: 700;
  margin-bottom: 0.5rem;
}
.news-single .post-navigation {
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-top: 2rem;
}

.post-featured-section .s-inner-wrap {
  padding-top: 20px;
}
.post-featured-section .featured-image {
  max-height: 400px;
  min-height: 250px;
  width: auto;
  align-self: center;
}