.block-quote {
	padding: var(--fluid-h4);
	font-weight: 700;
	font-size: var(--fluid-h5);
	line-height: 1.2;
	max-width: 225px;
	background: $primary-color;
	color: white;

	&.quote-black {
		background: $black;
	}

	&.quote-sm {
		font-size: var(--fluid-h6);
		padding: var(--fluid-h6);
	}
}
