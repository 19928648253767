/**
 * Parent and Core Style Imports
 *
 * Imports all core mixins and all template/component styles
 *
 * DO NO EDIT OR REMOVE THESE DIRECTLY.
 *
 **/

// Imports the core output styles from settings and the mixins/vars that come with settings
@import "core";

@include sanitizer-setter();
@include general-typography-setter();

//partials
@import "partials/fluid-typography";

// Modules
@import "Parent/scss/modules/supers/supers";
//@import "Parent/scss/components/fontawesome";

// Components
@import "components/buttons";
@import "components/links";
@import "components/navigation";
@import "components/footer";
@import "components/grids";
@import "components/block-quote";
@import "components/cube-title";
@import "components/body";
@import "components/forms";

// Better Menu
@import "./components/better-menu/better-menu";
@import "./components/better-menu/better-menu-theme";

// Better Modal
@import "./components/better-menu/better-modal";
@import "./components/better-menu/better-modal-theme";

// Templates
@import "templates/front";
@import "templates/page";
@import "templates/404";
@import "templates/news";
@import "templates/news-single";
