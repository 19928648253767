.header-nav {
	position: relative;
	display: flex;
	flex-flow: column nowrap;
	width: 100%;
	z-index: 100;
	background-color: $white;
	box-shadow: 5px 5px 10px rgba($black, 0.3);
	padding-top: 0.5rem;
	padding-bottom: 0.5rem;

	.header-row {
		display: flex;
		flex-flow: column nowrap;

		.header-row-inner-wrap {
			display: flex;
			flex-flow: row nowrap;
			justify-content: space-between;
			width: 100%;
			max-width: $global-width;
			margin: 0 auto;
			padding: 10px $padding;
		}

		.header-cell {
			display: flex;
			gap: 20px;
		}

		.header-link {
			display: flex;
			align-items: center;
			color: $secondary-color;
			border: unset;
			background: unset;
			font-family: $body-font-family;
			font-size: var(--fluid-body-sm);
			font-weight: 700;
		}
	}

	.nav-cell {
		.fancy-btn {
			display: none;
			padding-left: 1em;
			padding-right: 1em;
			font-size: var(--fluid-body-sm);

			@include mq(l) {
				display: block;
			}
		}
	}

	.logo-container {
		display: flex;
		flex-flow: row nowrap;
		align-items: center;
		justify-content: center;
		text-decoration: none;
		align-self: center;

		.logo {
			height: var(--fluid-h2);
		}
		.logo-name {
			margin-left: 1em;
			font-size: var(--fluid-h6);
			display: none;

			@include mq(m) {
				display: flex;
			}
		}
	}

	.top-row {
		background: $tertiary-color;
		.header-row-inner-wrap {
			padding: 0 0 0 10px;
			justify-content: flex-end;

			.header-link {
				padding: 10px 0;
				margin-left: 0.4em;
				transition: color 0.3s ease;
				display: flex;
				flex-flow: row nowrap;
				text-decoration: none;
				color: white;
				font-size: var(--fluid-body-sm);

				@include mq(s) {
					flex-flow: row nowrap;
					margin-left: 1em;
				}

				@include mq(m) {
					margin-left: 2em;
				}

				&:first-child {
					margin-left: 0;
				}

				&:hover,
				&:active,
				&:focus {
					color: $secondary-color;
				}
			}

			.header-bold-link {
				background-color: $secondary-color;
				padding-left: 2em;
				padding-right: 2em;
				transition: background-color 0.3s ease;

				&:hover,
				&:active,
				&:focus {
					color: white;
					background-color: $secondary-color-dark;
				}

				&:active {
					background-color: $secondary-color;
				}
			}
			button.header-link {
				cursor: pointer;
			}

			.callout-link {
				background-color: $primary-color;
				padding: 10px 14px 1em;
				margin-bottom: -8px;
				color: white;
				transition: background 0.3s ease;

				.icon {
					color: $secondary-color;
				}

				&:hover,
				&:active,
				&:focus {
					background-color: $primary-color-light;
					color: white !important;
				}
			}
			button {
				padding: 0;
			}
		}
	}
}

// Better menu mods

#desktop-nav {
	display: none;

	@include mq(l) {
		display: flex;
	}

	div:first-child {
		display: flex;
	}

	.menu {
		display: flex;
		justify-content: center;
		align-items: center;
		height: 100%;
	}

	a {
		text-transform: uppercase;
		font-weight: 700;
		letter-spacing: 1px;
		margin-left: 0.2rem;
		margin-right: 0.2rem;
	}
}

#mobile-nav-button {
	display: flex;

	@include mq(l) {
		display: none;
	}
}

#navigation-modal {
	[data-bm-card] {
		display: flex;
		flex-flow: column nowrap;
		width: 100%;
		background: $black;
		height: 100%;

		@include mq(m) {
			width: auto;
			min-width: 40%;
		}

		.bm-card-inner {
			padding: 2em;
			display: flex;
			flex-flow: column nowrap;
			flex-grow: 1;
		}

		[data-bm-button] {
			margin-left: auto;
			padding: 1rem;
			font-weight: 500;
			cursor: pointer;

			svg {
				padding: 0.4rem 0.2rem;
				color: white;
			}
		}

		a {
			color: white;
			align-self: center;
			text-align: center;
			font-size: var(--fluid-h6);
			margin-bottom: 1em;
		}

		#mobile-nav {
			margin: auto;
		}

		.mobile-callout-btn {
			width: 100%;
			display: flex;
			margin-top: auto;
			text-align: center;
			justify-content: center;
		}
	}
}
