#not-found-page {
	.fancy-btn {
		text-align: center;
		align-self: stretch;
		justify-content: center;
		margin-bottom: 1em;
		width: auto;
	}

	.callout {
		margin-top: 0.2em;
		font-size: var(--fluid-h6);
	}

	.btn-wrap {
		display: flex;
		flex-flow: column nowrap;
		width: 275px;
		max-width: 100%;
	}
}
