.news-archive {
	.c-grid {
		--grid-col-lg: 1fr 1fr 1fr;
		--grid-col-md: 1fr 1fr;
		--grid-col-sm: 1fr;

		--grid-gap: 25px;

		@include mq(m) {
			--grid-gap: 30px;
		}

		@include mq(l) {
			--grid-gap: 40px;
		}
	}
}
