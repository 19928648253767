.fluentform {
	form {
		.ff-btn {
			border: unset !important;
			background: $primary-color !important;
			@extend .fancy-btn;
			font-size: var(--fluid-body);

			&:hover,
			&:active,
			&:focus {
				opacity: 1;
				background: $primary-color-dark !important;
			}

			&:active {
				background: darken($primary-color-dark, 5%) !important;
			}
		}

		.ff-el-form-control {
			border-radius: 0;
		}
	}

	textarea {
		font-family: $body-font-family;
	}
}
