.cube-title {
	display: flex;
	flex-flow: row nowrap;
	align-content: center;
	justify-content: center;
	align-self: flex-start;

	&:before {
		content: "";
		display: flex;
		height: 1em;
		width: 1em;
		margin-right: 14px;
		background-color: $secondary-color;
		flex-shrink: 0;
		margin-top: auto;
		margin-bottom: auto;
	}

	&.title-green {
		&:before {
			background-color: $primary-color;
		}
	}
}
