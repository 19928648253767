@import "../partials/fluid-typography";

#hero {
	position: relative;

	&:after {
		content: "";
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		z-index: 0;
		@supports (backdrop-filter: brightness(0.5)) {
			backdrop-filter: brightness(0.5);
		}

		@supports not (backdrop-filter: brightness(0.5)) {
			background: rgba($black, 0.6);
		}
	}

	.s-inner-wrap {
		position: relative;
		z-index: 1;
		width: 600px;
		padding-top: 120px;
		padding-bottom: 30px;
	}

	h1 {
		text-align: center;
		color: white;
	}

	.words-wrap {
		display: flex;
		align-items: center;
		color: white;
		font-weight: bold;
		font-size: var(--fluid-h6);
		@include fluid-prop(font-size, 12px, 20px);
		text-transform: uppercase;

		@include fluid-prop(--circle-size, 4px, 8px);
		.circle {
			display: block;
			margin: var(--circle-size);
			width: var(--circle-size);
			height: var(--circle-size);
			border-radius: 500px;
			background: white;
		}
	}

	.hero-footer-image {
		margin-top: 75px;
	}

	.fancy-btn {
		margin: 30px 0;
		align-self: center;
	}
}

#together {
	.f-grid {
		--cell-size-lg: auto;
		--cell-size-md: auto;
		--grid-gap: 40px;
		align-items: center;
		justify-content: center;
		max-width: 100%;
		@include mq(m) {
			flex-flow: row nowrap;
		}

		.cell {
			max-width: 100%;
		}
		.quote-cell {
			flex-shrink: 0;
			align-items: flex-start;

			@include mq(m) {
				align-items: flex-start;
			}
		}

		.info-wrap {
			width: 100%;
			max-width: 600px;
			margin: 0 auto;
			font-size: var(--fluid-body-lg);
			font-weight: 500;

			p:first-child {
				margin-top: 0;
			}

			@include mq(m) {
				margin: unset;
			}
		}
	}
}

#program-benefits {
	background-color: $tertiary-color;
	h2 {
		text-align: center;
		align-self: center;
	}

	.c-grid {
		--grid-col-lg: repeat(4, 1fr);
		--grid-col-md: repeat(2, 1fr);
		--grid-col-sm: repeat(2, 1fr);
		--grid-gap: 10px;

		@include mq(m) {
			--grid-gap: 20px;
			width: 500px;
		}

		@include mq(l) {
			width: auto;
		}
		margin: 20px auto;
	}

	.block-quote {
		display: flex;
		align-items: center;
		width: 100%;
		height: 100%;
		max-width: unset;
		@include fluid-prop(padding, 12px, 25px);
		@include fluid-prop(font-size, 18px, 24px);
		background-repeat: no-repeat;
		background-position: center center;
		background-size: cover;

		aspect-ratio: 1/0.7;


	}

	a.block-quote {
		text-decoration: none;

		&:hover, &:active, &:focus {
			transform: scale(1.05);
		}
	}

	.fancy-btn {
		align-self: center;
		margin-top: 20px;
	}
}

#more-info {
	.c-grid {
		--grid-gap: 40px;
	}

	.info-cell {
		.fancy-btn {
			margin-top: 1rem;
			align-self: flex-start;
		}
	}

	.video-cell {
		.video-button {
			display: flex;
			flex-flow: column nowrap;
			align-items: center;
			justify-content: center;
			background-image: url("../images/conference-room.jpg");
			background-position: bottom center;
			background-size: cover;
			box-shadow: inset 0 0 0 2000px rgba($black, 0.4);
			border: unset;
			padding: 30px;
			color: white;
			font-weight: 600;
			font-size: var(--fluid-body-lg);
			transition: all 0.3s ease;
			.video-triangle {
				padding-bottom: 45%;
				width: 45%;
				height: auto;
				background-color: rgba(255, 255, 255, 0.6);
				clip-path: polygon(20% 15%, 20% 85%, 80% 50%);
				z-index: 1;
				transition: all 0.3s ease;
			}
			&:hover {
				transform: scale(1.02);
				background-color: rgba(255, 255, 255, 0.4);
			}

			&:active,
			&:focus {
				.video-triangle {
					background-color: rgba(255, 255, 255, 0.9);
				}
			}
		}
	}
}

// Modal schmutz
.video-modal {
	--bm-overlay-color: rgba(0, 0, 0, 0.3);
	[data-bm-card] {
		width: $global-width;
		max-width: 100%;
		margin: 0 auto;
		padding: 1rem;

		@include breakpoint(medium) {
			max-width: 85%;
		}

		@include breakpoint(large) {
			max-width: 1200px;
		}
	}

	[data-bm-close] {
		color: white;
		font-size: 4rem;
		margin-left: auto;
		filter: drop-shadow(0 0 4px black);
	}

	.video-wrap {
		position: relative;
		width: 100%;
		flex-grow: 1;
		padding-bottom: 56.25%;
		margin: 1rem;
		margin: 0 auto;

		video, iframe {
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
		}
	}
}

#home-news {
	background-color: $tertiary-color;
	--swiper-theme-color: #{$black};
	--swiper-navigation-size: 35px;

	#home-slider {
		position: relative;
		margin-top: 40px;
		padding-left: 50px;
		padding-right: 50px;
	}

	.swiper {
		.swiper-wrapper {
		}

		.swiper-slide {
			flex-grow: 1;
			height: auto;
			padding-bottom: 15px;
		}
	}
}

.news-card {
	display: flex;
	flex-flow: column nowrap;
	height: 100%;
	text-decoration: none;
	border-radius: 5px;
	box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.2);
	padding: 10px;
	margin: 10px;
	background-color: rgba(white, 0.7);

	.image-wrap {
		position: relative;
		padding-bottom: 80%;
		margin-bottom: 0.5rem;
		transition: filter 0.3s ease;
		border-radius: 4px;
		overflow: hidden;
		img {
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			object-fit: cover;
			object-position: center center;
		}
	}

	.info-wrap {
		display: flex;
		flex-flow: column nowrap;
		flex-grow: 1;
		text-decoration: none;
		color: $black;

		.news-date {
			color: $primary-color;
			font-weight: bold;
			margin: 0.3em 0;
			text-decoration: none !important;
		}
		.news-link {
			margin-top: auto;
			align-self: flex-end;
			text-decoration: none;
		}

		.news-title {
			font-weight: 700;
			line-height: 1.4;
			//font-size: var(--fluid-body-sm);
			margin-bottom: 1rem;
		}
	}

	&:hover,
	&:focus {
		.image-wrap {
			filter: brightness(0.9);
		}

		.fancy-link {
			color: $primary-color-dark;
		}
	}

	&:active {
		.fancy-link {
			color: darken($primary-color-dark, 5%);
		}
	}

	.fancy-link {
		color: $primary-color;
		transform: all 0.3s ease;
	}
}

#office-space {
	.s-inner-wrap {
		padding-bottom: calc(var(--fluid-section-padding) / 1.5);
	}
	.c-grid {
		margin-top: 20px;
		--grid-gap: 20px;
		--grid-col-md: 1fr 1fr;
		width: 400px;
		max-width: 100%;
		margin-left: auto;
		margin-right: auto;

		@include mq(m) {
			width: 100%;
		}
	}

	.office-card {
		display: flex;
		flex-flow: column nowrap;
		align-items: center;
		justify-content: center;
		padding: 80px 40px;
		background-position: center center;
		background-size: cover;
		background-repeat: no-repeat;
		box-shadow: inset 0 0 0 2000px rgba($black, 0.6);
		height: 100%;
		aspect-ratio: 100 / 40;
		span {
			text-align: center;
			font-size: var(--fluid-h4);
			color: white;
			font-weight: bold;
			max-width: 100%;
			margin-bottom: 50px;
			line-height: 1.2;
		}
		.fancy-btn {
			align-self: center;
		}
	}
}

#partners {

	#partner-slider {
		margin-top: 50px;
		margin-bottom: 50px;
	}

	.swiper-wrapper {
		display: flex;
		transition-timing-function: linear;
	}
	.swiper-slide {
		display: flex;
		flex-flow: column nowrap;
		align-items: center;
		align-content: center;
		justify-content: center;
		justify-items: center;
		height: 100%;
		width: auto;
	}

	img {
		max-height: 115px;
		transition: all 0.3s ease;
		transform: scale(0.9);
		&:hover,
		&:focus {
			transform: scale(1);
		}

		&:active {
			transform: scale(1.02);
		}
	}
}
