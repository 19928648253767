/**
 * Link styling
 * Links can get pretty fancy so the setting partials do not get involved
 **/

a {
	color: $primary-color;
	font-weight: bold;
	transition: all 0.3s ease;

	&:hover,
	&:focus {
		color: $primary-color-dark;
	}

	&:active {
		color: darken($primary-color-dark, 5%);
	}
}

.fancy-link {
	position: relative;
	text-decoration: none;
	padding-bottom: 5px;
	font-weight: 700;

	&:after {
		content: "";
		position: absolute;
		bottom: 2px;
		left: 0;
		width: 100%;
		height: 5px;
		background-color: currentColor;
	}
}
