.body-controller {
	display: flex;
	flex-flow: column nowrap;
	min-height: 100vh;

	footer {
		margin-top: auto;
	}
}

body {
	margin: 0;

	&:not([data-s-loaded]) {
		* {
			transition: none !important;
		}
	}
}

// Make sure all columns are flex for easy 100% height divs
.uagb-column__inner-wrap {
	display: flex;
	flex-flow: column nowrap;
}
